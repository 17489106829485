import React, { Component } from "react";
import {Grid,Image,Icon,Form,Button,Dimmer,Loader,Segment} from "semantic-ui-react";
import AddCartModal from "../../shared/components/organisms/modal/add-cart/index";
import PluginModal from "../../shared/components/organisms/modal/plugin-Modal/index";
import ProductDetailPage from "../home/product-details";
import AddCartPopUp from "./add-cart-popup";
import RentalPeriodModal from "../../shared/components/organisms/modal/rental-period/index";
import {commonFunctions,connect,bindActionCreators,actions,withRouter,Notifications} from "../../shared/functional/global-import";
import { empty } from "../../shared/functional/global-image-import";
import MyCartPage from "../my-cart/my-cart";
import {CheckoutModal,AddToCartModal} from "../../shared/components/organisms/modal";
import * as env from "../../config/env.config";
import BannerImage from "./add-bannerImage";
import LocationForHeader from "./Location-Header";
import HeaderMenu from "./headerAll";
import LocationViewFolder from "./add-Showlocation";
import LocationViewWordpressFolder from "./add-locationviewWordpress";
import LocationViewData from "./add-locationData";
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocationSelector: false,
      directWordpressCheckout: false,
      addToCartWordpressCheckout: false,
      newCartStatus: false,
      HomePage: false,
      individualProductBookNowDetails: {},
      bookWordpressitem: false,
      isToOpenItems: false,
      bookNow: false,
      isheaderMenu: false,
      isBookNowWidget: false,
      isbookNowFunction: false,
      date: "",
      time: "",
      dateTime: "",
      datesRange: "",
      items: false,
      locatoion: false,
      banner: false,
      allCategories: [],
      insideWordpress: false,
      allData: "",
      LOCATIONFORBUSINESS: [],
      bannerImage: "",
      allProducts: [],
      itemPrice: "",
      addItem: "",
      nocategory: false,
      ProductDetailPage: false,
      productDetail: "",
      locations: "",
      categorySelected: "",
      itemId: 0,
      allTags: [],
      parentId: "",
      availabilitySchedule: {
        startDate: "", 
        EndDate: "",
        startTime: "", 
        endTime: "", 
      },
      startDate: "",
      EndDate: "",
      startTime: "",
      endTime: "",
      shopstartTime: "",
      shopstartAm: "",
      isShowAvailableItem: null,
      isShowAvailabletickets: null,
      businessLocationId: null,
      businesscategoryId: null,
      businessId: null,
      myCartPage: false,
      bookNowRightButton: false,
      historyCall: this.props.history,
      isBannerImage: false,
      isLocationsHeader: false,
      isHeaderWidget: false,
      BannerImageMenu: true,
      bannerBusinessLocationId: null,
      isMuiltiplyLocationView: false,
      multipltLocationData: [],
      showlocationViewData: false,
      locationViewId: null,
      isMuiltiplyLocationWordpressView: false,
      locationViewWordpress: false,
      showBannerCategory: false,
      bannerCatergories: [],
      bussinessPathName: null,
      bussinessPathUrl: null,
      isLoading: false,
      locationView: false,
      bookNowViewfunction: false,
      showCountCart: false,
    };
  }

  showItems = (e) => {
    if (e !== undefined) {
      this.setState({
        items: false,
        locatoion: true,
        banner: true,
        ProductDetailPage: false,
        bookNowRightButton: true,
        BannerImageMenu: false,
        isMuiltiplyLocationView: false,
        bookNowViewfunction: false,
        showCountCart: true,
      });
    } else {
      this.setState({
        items: true,
        locatoion: false,
        banner: true,
        ProductDetailPage: false,
        BannerImageMenu: false,
        isMuiltiplyLocationView: false,
        bookNowViewfunction: false,
        showCountCart: true,
      });
    }
    if (e === "location") {
      this.setState({ items: false, banner: true });
      this.getLocationForBusiness(this.state.businessId);
    }
  };
  
  showItem = (e) => {
    if (e !== undefined && e.parentId === null) {
      this.setState({
        items: true,
        banner: true,
        locatoion: false,
        ProductDetailPage: false,
        businessCategoryType: e.businessCategoryType,
        bookNowRightButton: true,
        BannerImageMenu: false,
        isMuiltiplyLocationView: false,
        bookNowViewfunction: false,
        showCountCart: true,
      });
      this.GETCATEGORIESFORBUSINESS(e);
    } else {
      this.setState({
        itemImage: e.Image,
        itemName: e.ProductName,
        cartStatus: !this.state.cartStatus,
        itemPrice: e.Price,
        isMuiltiplyLocationView: false,
        bookNowViewfunction: false,
        showCountCart: true,
      });
    }
    this.setState({ categorySelected: "" });
  };

  onIndividualProductFun = () => {
    var prodictDetails = document.getElementsByClassName(
      "individualproductbook"
    );
    if (prodictDetails) {
      for (var m = 0; m < prodictDetails.length; m++) {
        let categoryId =
          prodictDetails[m].attributes?.categoryid?.value === undefined
            ? null
            : prodictDetails[m].attributes?.categoryid?.value === "null"
            ? null
            : prodictDetails[m].attributes?.categoryid?.value === "{null}"
            ? null
            : prodictDetails[m].attributes?.categoryid?.value;
        let obj = {
          businesscategoryId:
            prodictDetails[m].attributes.businesscategoryid.value,
          categoryId: categoryId,
          businessId: prodictDetails[m].attributes.businessId.value,
          businessLocationId:
            prodictDetails[m].attributes.businessLocationId.value,
          productId: prodictDetails[m].attributes?.productid?.value,
        };
        prodictDetails[m].addEventListener("click", () =>
          this.onClickBookBtn(obj)
        );
      }
    }
  };

  onClickBookBtn = (obj) => {
    const obj2 = {
      businessId: obj?.businessId,
      businessLocationId: obj?.businessLocationId,
      categoryId: obj?.categoryId,
      businessCategoryType: obj?.businesscategoryId,
      productId: obj?.productId,
    };
    this.onClickIndividualProduct(obj2);
    this.setState({
      businessLocationId: obj?.businessLocationId,
      businesscategoryId: obj?.businesscategoryId,
      businessId: obj?.businessId,
    });
  };

  componentDidMount() {
    setTimeout(() => this.onIndividualProductFun(), 1000);
    if (this.state.bookWordpressitem !== true) {
      this.getBusinessCategoryWebsiteWidget();
      this.getGlobalCode();
    }
    this.getAllGlobalCode();
    this.props.actions.DeleteItemIndividualDetail();
    this.setState({
      showCountCart: true,
    });
  }

  allCatergoriesBanner = (id) => {
    this.props.actions.apiCall({
      urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
      method: "GET",
      data: {
        WebsiteUrl: this.props.global.bussinessUrl,
        BusinesslocationId: id,
      },
      onSuccess: (response) => {
        const allCategorShow = JSON.parse(response[0].categories).filter(
          (item) => item.Categories != null
        );
        this.setState({
          bannerCatergories: allCategorShow,
          showBannerCategory: true,
        });
      },
    });
  };

  getGlobalCode = () => {
    this.props.actions.apiCall({
      urls: ["GLOBALCODE"],
      method: "GET",
      data: {
        CategoryName: "State",
        GlobalCodeId: -1,
      },
      onSuccess: (response) => {
        const globalCode = response.map((singleData) => {
          return { text: singleData.codeName, value: singleData.globalCodeId };
        });
        this.props.actions.storeGlobalCodes(globalCode);
      },
      showNotification: false,
    });
  };
  getAllGlobalCode = () => {
    this.props.actions.apiCall({
      urls: ["GLOBALCODE"],
      method: "GET",
      data: {
        GlobalCodeId: -1,
      },
      onSuccess: (response) => {
        localStorage.setItem("allGlobalCodes", JSON.stringify(response));
      },
      showNotification: false,
    });
  };
  getWidgetLocaion() {
    this.props.actions.apiCall({
      urls: ["GETBUSINESSLOCATION"],
      method: "GET",
      data: {
        InWebsiteWidget: 1,
        BusinessId: this.props.global.addBussinessId,
        BusinessLocationId: -1,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        let locationData = response.map((post) => {
          return {
            text: post.businessLocationName,
            value: post.businessLocationId,
            id: post.categoryIdDataExist,
          };
        });

        this.setState({ locationOption: locationData });
      },
    });
  }
  openSubCategoty = (e, { product }) => {
    if (product.productId !== 0 && e.target.className !== "cart plus icon") {
      this.setState({
        items: false,
        ProductDetailPage: true,
        productDetail: product,
      });
    }
    else {
      this.GETCATEGORIESFORBUSINESS(product);
    }
  };

  GETCATEGORIESFORBUSINESS = (e) => {
    this.setState({ isLoading: true });
    if (this.state.businessCategoryType !== undefined) {
      this.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: this.state.businessId,
          BusinessLocationId: e.businessLocationId,
          ParentId: e.categoryId,
          BusinessCategoryType: e.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
          startDate: this.state.startDate,
          EndDate: this.state.EndDate,
          startTime: this.state.startTime,
          endTime: this.state.endTime,
        },
        onSuccess: (response) => {
          this.props.actions.storeShowCount(true);
          if (response[0]?.shopStartTime) {
            const [hours, amPM] = response[0].shopStartTime.split(":00");
            this.setState({ shopstartTime: hours, shopstartAm: amPM });
          }
          if (response.length > 0) {
            this.setState({
              items: true,
              locatoion: false,
              nocategory: false,
            });
            this.setState({ allProducts: response });
            this.props.actions.storeParentId(e.categoryId);
            this.props.actions.storeShowCount(true);
            this.setState({ locations: response[0].businessLocationId });
          } else {
            this.setState({ nocategory: true, isLoading: true, items: false });
          }
        },
      });
    } else {
      this.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: this.state.businessId,
          BusinessLocationId: e.businessLocationId,
          ParentId: e.categoryId,
          BusinessCategoryType: e.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
          startDate: this.state.startDate,
          EndDate: this.state.EndDate,
          startTime: this.state.startTime,
          endTime: this.state.endTime,
        },
        onSuccess: (response) => {
          if (response[0]?.shopStartTime) {
            const [hours, amPM] = response[0].shopStartTime.split(":00");
            this.setState({ shopstartTime: hours, shopstartAm: amPM });
          }
          if (response.length > 0) {
            this.setState({
              items: true,
              locatoion: false,
              nocategory: false,
            });
            this.setState({ allProducts: response });
            this.props.actions.storeParentId(e.categoryId);
            this.props.actions.storeShowCount(true);
            this.setState({ locations: response[0].businessLocationId });
          } else {
            this.setState({ nocategory: true, items: false });
          }
        },
      });
    }
  };

  getBusinessUrlForcodEembedded = (e) => {
    let bussinessPathName = document
      .getElementById("rehntit-website-widget")
      .getAttribute("bussinesId");
    let bussinessLocationId = document
      .getElementById("rehntit-website-widget")
      .getAttribute("bussinesslocationid");
    bussinessLocationId =
      bussinessLocationId === null ? 0 : bussinessLocationId;
    this.setState({ bussinessPathName: bussinessPathName });
    this.props.actions.apiCall({
      urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
      method: "GET",
      data: {
        WebsiteUrl:
          bussinessPathName === ""
            ? this.props.global.bussinessUrl
            : bussinessPathName,
        BusinesslocationId: bussinessLocationId,
      },
      onSuccess: ([response]) => {
        this.setState({
          isheaderMenu: response.isheaderMenu,
          isHeaderWidget: response.isHeaderWidget,
          isbookNowFunction: response.isbookNowFunction,
          isShowAvailableItem: response.isShowAvailableItem,
          isLocationSelector: response.isLocationSelector});

        if (response.errorCode !== "500") {
          if (response.isBookNow) {
            this.setState({
              bookNow: response.isBookNow,
            });
            let themeColor = response.color;
            const root = document.documentElement;
            root?.style.setProperty("--main-color", themeColor);
            this.props.actions.storeLocationTimeZone(response.locationTimeZone);
            this.props.actions.storeThemeColor(response.color);
            this.props.actions.storeBussinessId(response.businessId);
            this.props.actions.storeSkipOptionData(response.waiverSkipButton);
            const { isMuiltiplyLocationWordpressView, businessId } = response;
            this.getLocationForBusiness(
              businessId,
              isMuiltiplyLocationWordpressView
            );
            this.props.actions.storeShowCount(true);
          } else {
            const allCartItems = this.props.global.allProductDetail.products;
            const cartBusinessIds = allCartItems.map((item) => {
              return item.addItem.businessId;
            });
            const currentBusinessId = response.businessId;
            const isSameId = cartBusinessIds.every(
              (currentValue) => currentValue === currentBusinessId
            );
            if (!isSameId) {
              this.props.actions.DeleteAllItemDetail();
            }
            let themeColor = response.color;
            const root = document.documentElement;
            root?.style.setProperty("--main-color", themeColor);
            this.props.actions.StoreBussinessUrl(bussinessPathName);
            this.props.actions.storeThemeColor(response.color);
            this.props.actions.storeLocationTimeZone(response.locationTimeZone);
            this.props.actions.storeBussinessId(response.businessId);
            this.props.actions.storeSkipOptionData(response.waiverSkipButton);
            this.props.actions.storeBussinessDetail(response.image);
            this.setState({
              businessId: response.businessId,
              isLocationSelector: response.isLocationSelector,
              insideWordpress: true,
              bookNow: false,
            });
            this.setState({
              allCategories: JSON.parse(response.categories).filter(
                (item) => item.Categories != null
              ),
            });

            this.props.actions.SHOWHEADERWEBSITE(response.widgetHeader);
            this.props.actions.storeInsideWordpress(true);
            this.setState({ banner: true });
            this.setState({
              allTags:
                JSON.parse(response.categories)?.[0].Categories === null
                  ? []
                  : JSON.parse(
                      JSON.parse(response.categories)[0].Categories[0].Tags
                    ),
            });
            this.getWidgetLocaion();
          }
        } else {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: response.responseMessage,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
      },
    });
  };

  getBusinessCategoryWebsiteWidget = (e) => {
    const environmentName = env.NODE_ENV;
    const isProductionEnvironment = environmentName === "production";
    const isWidgetApp = window.location.hostname.indexOf("ahoybooking.com");
    if (
      this.state.insideWordpress === false &&
      (isProductionEnvironment ? isWidgetApp > -1 : true)
    ) {
      if (e === undefined) {
        let bussinessPathName = window.location.hostname.split(".")[0];
        isProductionEnvironment
          ? (bussinessPathName = bussinessPathName)
          : (bussinessPathName = "alwaysanotherwave");
        //
        if (bussinessPathName === "www") {
          window.location = "https://ahoybooking.com/";
        } else {
          if (bussinessPathName !== "") {
            this.props.actions.StoreBussinessUrl(bussinessPathName);
          }
          this.props.actions.apiCall({
            urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
            method: "GET",
            data: {
              WebsiteUrl:
                bussinessPathName === ""
                  ? this.props.global.bussinessUrl
                  : bussinessPathName,
              BusinesslocationId: 0,
            },
            onSuccess: ([response]) => {
              const { isMuiltiplyLocationView, businessId, isBookNowWidget } =
                response;
              const bookNowViewfunction =
                isBookNowWidget && isMuiltiplyLocationView;
              this.setState({
                isheaderMenu: true,
                isBannerImage: response.isBannerImage,
                isHeaderWidget: response.isHeaderWidget,
                isBookNowWidget: response.isBookNowWidget,
                isLocationsHeader: response.isLocationsHeader,
                isShowAvailableItem: response.isShowAvailableItem,
                bannerBusinessLocationId: response.businessLocationId,
                isShowAvailabletickets: response.isShowAvailabletickets,
                isLocationSelector: response.isLocationSelector,
                bookNowViewfunction: bookNowViewfunction,
              });
             
              if (bookNowViewfunction) {
                this.getLocationForBusiness(
                  businessId,
                  isMuiltiplyLocationView,
                  bookNowViewfunction
                );
              }
              if (!response.isBannerImage) {
                this.setState({
                  isMuiltiplyLocationView,
                });
                this.getLocationForBusiness(
                  businessId,
                  isMuiltiplyLocationView
                );
              }
              this.props.actions.storeBussinessLocationId(
                response.businessLocationId
              );
              this.props.actions.storeShowCount(true);
              if (response.errorCode !== "500") {
                const allCartItems =
                  this.props.global.allProductDetail.products;
                const cartBusinessIds = allCartItems.map((item) => {
                  return item.addItem.businessId;
                });
                const currentBusinessId = response.businessId;
                const isSameId = cartBusinessIds.every(
                  (currentValue) => currentValue === currentBusinessId
                );
                if (!isSameId) {
                  this.props.actions.DeleteAllItemDetail();
                }
                let themeColor = response.color;
                const root = document.documentElement;
                root?.style.setProperty("--main-color", themeColor);
                this.props.actions.storeThemeColor(response.color);
                this.props.actions.storeShowCount(true);
                this.props.actions.storeLocationTimeZone(
                  response.locationTimeZone
                );
                this.props.actions.storeInsideWordpress(false);
                this.props.actions.storeBussinessId(response.businessId);
                this.props.actions.storeSkipOptionData(
                  response.waiverSkipButton
                );
                this.props.actions.storeBussinessDetail(response.image);
                this.setState({
                  businessId: response.businessId,
                  isLocationSelector: response.isLocationSelector,
                });
                this.setState({
                  allCategories: JSON.parse(response.categories).filter(
                    (item) => item.Categories != null
                  ),
                });
                this.props.actions.SHOWHEADERWEBSITE(response.widgetHeader);
                this.setState({ bannerImage: response.websitewidgetImage });
                this.setState({
                  allTags:
                    JSON.parse(response.categories)?.[0].Categories === null
                      ? []
                      : JSON.parse(
                          JSON.parse(response.categories)[0]?.Categories[0].Tags
                        ),
                });
                this.getWidgetLocaion();
              } else {
                this.getBusinessUrlForcodEembedded();
              }
            },
          });
        }
      } else {
        this.props.actions.apiCall({
          urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
          method: "GET",
          data: {
            WebsiteUrl: this.props.global.bussinessUrl,
            BusinesslocationId:
              e.businessLocationId === undefined ? e : e.businessLocationId,
          },
          onSuccess: ([response]) => {
            if (response.errorCode !== "500") {
              let themeColor = response.color;
              const root = document.documentElement;
              root?.style.setProperty("--main-color", themeColor);
              this.setState({
                businessId: response.businessId,
                isLocationSelector: response.isLocationSelector,
              });
              this.setState({
                allCategories: JSON.parse(response.categories).filter(
                  (item) => item.Categories != null
                ),
              });
              this.setState({ bannerImage: response.websitewidgetImage });
              this.props.actions.storeBussinessId(response.businessId);
              this.props.actions.storeSkipOptionData(response.waiverSkipButton);
              // this.props.actions.storeShowCount(true);
              this.props.actions.storeLocationTimeZone(
                response.locationTimeZone
              );
              this.props.actions.SHOWHEADERWEBSITE(response.widgetHeader);
              this.props.actions.storeBussinessDetail(response.image);
              this.getWidgetLocaion();
              this.setState({
                allTags:
                  JSON.parse(response.categories)?.[0].Categories === null
                    ? []
                    : JSON.parse(
                        JSON.parse(response.categories)[0].Categories[0].Tags
                      ),
              });
            }
          },
        });
      }
    } else {
      this.getBusinessUrlForcodEembedded();
    }
  };

  getLocationForBusiness = (
    dataLocation,
    isMuiltiplyLocationView,
    bookNowViewfunction
  ) => {
    this.props.actions.apiCall({
      urls: ["GETLOCATIONFORBUSINESS"],
      method: "GET",
      data: { BusinessId: dataLocation },
      onSuccess: (response) => {
        if (response.length === 1) {
          this.setState({
            LOCATIONFORBUSINESS: response,
            multipltLocationData: response,
            nocategory: false,
            isMuiltiplyLocationView: false,
            isMuiltiplyLocationWordpressView: false,
            bookNowViewfunction: false,
          });         
        } else if (bookNowViewfunction) {
          this.setState({
            LOCATIONFORBUSINESS: response,
            multipltLocationData: response,
            nocategory: false,
            isMuiltiplyLocationView: false,
            isMuiltiplyLocationWordpressView: false,
            bookNowViewfunction: true,
          });
        } else {
          this.setState({
            LOCATIONFORBUSINESS: response,
            multipltLocationData: response,
            nocategory: false,
            isMuiltiplyLocationView: isMuiltiplyLocationView,
            isMuiltiplyLocationWordpressView: isMuiltiplyLocationView,
          });
        }
        this.getWidgetLocaion();
        // this.props.actions.storeShowCount(true);
      },
    });
  };

  bookNowModal = () => {
    this.setState({
      isToOpenItems: false,
      bookWordpressitem: true,
    });
  };
  bookNowLocationModal = () => {
    this.setState({
      isToOpenItems: false,
      bookWordpressitem: false,
      locationView: !this.state.locationView,
    });
  };
  bookLocationModal = () => {
    this.setState({
      isToOpenItems: false,
      bookWordpressitem: false,
      locationViewWordpress: true,
    });
  };
  bookNowCloseModal = () => {
    this.setState({
      bookWordpressitem: false,
      individualProductBookNowDetails: {},
    });
    this.getBusinessCategoryWebsiteWidget();
  };
  closeModalWordpress = () => {
    this.setState({
      isToOpenItems: false,
      bookWordpressitem: false,
      locationViewWordpress: false,
      individualProductBookNowDetails: {},
    });
  };

  catergoriesForBusiness = (e) => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORIESFORBUSINESS"],
      method: "GET",
      data: {
        businessId: this.state.businessId,
        BusinessLocationId: e.businessLocationId,
        ParentId: "",
        StartDate: "",
        EndDate: "",
        StartTime: "",
        EndTime: "",
        BusinessCategoryType:
          e?.categoryIdDataExist === undefined ? 1 : e?.categoryIdDataExist,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        if (response.length === 0) {
          this.setState({
            items: true,
            nocategory: true,
            locatoion: false,
            locations: e.businessLocationId,
          });
        } else {
          this.setState({
            items: true,
            locatoion: false,
            nocategory: false,
            locations: response[0].businessLocationId,
          });
        }
        this.props.actions.storeBussinessLocationId(
          response[0]?.businessLocationId
        );
        this.setState({ allProducts: response });
        // this.props.actions.storeShowCount(true);
        this.getBusinessCategoryWebsiteWidget(e);
        if (this.state.insideWordpress === true) {
          this.props.actions.apiCall({
            urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
            method: "GET",
            data: {
              WebsiteUrl: this.props.global.bussinessUrl,
              BusinesslocationId:
                e.businessLocationId === undefined ? e : e.businessLocationId,
            },
            onSuccess: (response) => {
              if (response[0].errorCode !== "500") {
                let themeColor = response[0].color;
                const root = document.documentElement;
                root?.style.setProperty("--main-color", themeColor);
                this.setState({
                  businessId: response[0].businessId,
                  isLocationSelector: response[0].isLocationSelector,
                });
                this.setState({
                  allCategories: JSON.parse(response[0].categories).filter(
                    (item) => item.Categories != null
                  ),
                });
                this.setState({ bannerImage: response[0].websitewidgetImage });
                this.props.actions.storeBussinessId(response[0].businessId);
                this.props.actions.storeSkipOptionData(
                  response[0].waiverSkipButton
                );
                this.props.actions.SHOWHEADERWEBSITE(response[0].widgetHeader);
                this.props.actions.storeBussinessDetail(response[0].image);
                this.getWidgetLocaion();
                this.setState({
                  allTags:
                    JSON.parse(response[0].categories)?.[0].Categories === null
                      ? []
                      : JSON.parse(
                          JSON.parse(response[0].categories)[0].Categories[0]
                            .Tags
                        ),
                });
              }
            },
          });
        }
      },
    });
  };
  openInventory = (e) => {
    this.setState({
      allProducts: [],
      BannerImageMenu: false,
      isMuiltiplyLocationView: false,
      bookNowViewfunction: false,
    });
    this.catergoriesForBusiness(e);
  };

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  AddCartModal = (e) => {
    this.setState({
      cartStatus: !this.state.cartStatus,
      addItem: e,
      items: true,
      ProductDetailPage: false,
    });
  };
  AddCartNewModal = (e) => {
    this.setState({
      newCartStatus: true,
      addItem: e,
      items: true,
      ProductDetailPage: false,
    });
  };
  onCloseModal = () => {
    this.setState({
      isToOpenItems: true,
      bookWordpressitem: true,
      HomePage: true,
      newCartStatus: false,
      addItem: {},
      items: true,
      ProductDetailPage: false,
    });
  };

  onHandleChange = (e, { name, value, type, checked, data, id }) => {
    const businesscategoryId = e.target.id;
    this.setState({ locations: value });
    this.props.actions.apiCall({
      urls: ["GETCATEGORIESFORBUSINESS"],
      method: "GET",
      data: {
        businessId: this.state.businessId,
        BusinessLocationId: value,
        ParentId: "",
        StartDate: "",
        EndDate: "",
        StartTime: "",
        EndTime: "",
        BusinessCategoryType: businesscategoryId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        this.setState({ items: true, locatoion: false, nocategory: false });
        this.setState({ allProducts: response });
        if (response.length === 0) {
          this.setState({ nocategory: true, isLoading: true });
        } else {
          this.getBusinessCategoryWebsiteWidget(value);
        }
      },
    });
  };

  onHandleCategoryClick = (selected) => {
    this.setState({ categorySelected: selected, bookNowViewfunction: false });
  };

  AddRentalModal = () => {
    this.setState({
      availabilitySchedule: {
        ...this.state.availabilitySchedule,
        startDate: "",
        EndDate: "",
        startTime: "",
        endTime: "",
      },
    });
    this.setState({ rentalStatus: !this.state.rentalStatus });
  };
  backtoitem = () => {
    this.setState({ ProductDetailPage: false, items: true });
  };

  // =====new
  onClickIndividualProduct = (e) => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORIESFORBUSINESS"],
      method: "GET",
      data: {
        businessId: e.businessId,
        BusinessLocationId: e.businessLocationId,
        ParentId: e.categoryId,
        BusinessCategoryType: e.businessCategoryType,
        PageNo: 1,
        PageSize: 100,
        startDate: this.state.startDate,
        EndDate: this.state.EndDate,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
      },
      onSuccess: (response) => {
        this.setState({ items: true, locatoion: false, nocategory: false });
        this.setState({ allProducts: response });
        // let particularItemClicked =
        //   this.props?.individualProductBookNowDetails?.productId;
        let newObj1 = {};
        response &&
          response.length > 0 &&
          response.map((singleObj) => {
            if (singleObj.productId === parseInt(e.productId)) {
              newObj1 = singleObj;
            }
            return singleObj;
          });

        this.props.actions.storeParentId(e.categoryId);
        // this.props.actions.storeShowCount(true);

        if (response.length === 0) {
          this.setState({ nocategory: true, isLoading: true });
        } else {
          this.setState({ locations: response[0]?.businessLocationId });
        }
        this.AddCartNewModal(newObj1);
      },
    });
  };

  openaddtocart = () => {
    this.setState({
      items: false,
      myCartPage: true,
      ProductDetailPage: false,
      isBookNowWidget: false,
      isHeaderWidget: false,
      showCountCart: false,
      addToCartWordpressCheckout: !this.state.addToCartWordpressCheckout,
    });

  };

  onCloseWordpressDirectCheckout = () => {
    this.setState({ directWordpressCheckout: false });
    this.props.actions.DeleteItemIndividualDetail();
  };
  backToWordpressModal = () => {
                            
    if(this.state.isMuiltiplyLocationWordpressView){
      this.setState({
        directWordpressCheckout:false,
        addToCartWordpressCheckout: false,
        showlocationViewData: false,
        bookWordpressitem: true,
      });
    }
    else{
      this.setState({
        directWordpressCheckout:false,
        addToCartWordpressCheckout: false,
        showlocationViewData: true,
        bookWordpressitem: true,
      });

    }
  };
  onDirectlyOpenCheckout = () => {
    this.setState({ directWordpressCheckout: true, newCartStatus: false });
  };
  closeWordpressModal = () => {
    this.setState({ directWordpressCheckout: false });
    this.props.actions.DeleteItemIndividualDetail();
  };
  closeModalLocation = () => {
    if (this.state.insideWordpress) {
      this.setState({
        showlocationViewData: false,
        isToOpenItems: false,
        bookWordpressitem: false,
        locationViewWordpress: false,
        individualProductBookNowDetails: {},
      });
    } else {
      this.setState({
        showlocationViewData: false,
      });
    }
    this.props.actions.DeleteItemIndividualDetail();
    this.getBusinessCategoryWebsiteWidget();
  };
  onClickLocationView = (data, bussinessPathName) => {
    this.setState({
      showlocationViewData: !this.state.showlocationViewData,
      locationViewId: data.businessLocationId,
      bussinessPathUrl: bussinessPathName,
    });
  };
  onBacktoshop;
  render() {
    const { api } = this.props;
    const {
      allCategories,
      LOCATIONFORBUSINESS,
      allProducts,
      addItem,
      productDetail,
      allProductImages,
      locationOption,
      cartStatus,
      businessCategoryType,
      startDate,
      EndDate,
      startTime,
      shopstartTime,
      shopstartAm,
      endTime,
      bookNow,
      isbookNowFunction,
      isShowAvailableItem,
      isShowAvailabletickets,
      businessLocationId,
      businesscategoryId,
      businessId,
      directWordpressCheckout,
      addToCartWordpressCheckout,
      bookNowRightButton,
      isBannerImage,
      isBookNowWidget,
      isLocationsHeader,
      isHeaderWidget,
      isMuiltiplyLocationView,
      isMuiltiplyLocationWordpressView,
      bannerCatergories,
      showBannerCategory,
      isheaderMenu,
    } = this.state;
    return (
      <>
      {isheaderMenu === true ? ( 
          <div
            className={
              isBannerImage
                ? isHeaderWidget
                  ? "homePageAll "
                  : "homePageWidget"
                : isHeaderWidget
                ? "homePageAll bookWidget"
                : "homePageWidget bookWidget homePageAll"
            }
          >
            {this.state.insideWordpress ? null : (
              <>
                {isBookNowWidget ? (
                  <Button
                    className={
                      bookNowRightButton
                        ? "rightBookNow primaryBtn"
                        : "bookNowWidget primaryBtn"
                    }
                    onClick={
                      this.state.bookNowViewfunction
                        ? this.bookNowLocationModal
                        : this.bookNowModal
                    }
                  >
                    Book Now
                  </Button>
                ) : null}
              </>
            )}
            {/* Header Menu Common Component*/}
            {this.state.insideWordpress ? (
              <HeaderMenu
                showItems={this.showItems}
                showItem={this.showItem}
                BannerImageMenu={false}
                isLocationsHeader={isLocationsHeader}
                onHandleCategoryClick={this.onHandleCategoryClick}
                insideWordpress={this.state.insideWordpress}
                allFunction={this}
                fullState={this.state}
                allCategories={showBannerCategory ? bannerCatergories : allCategories
                }
                bannerCatergories={bannerCatergories}
                categorySelected={this.state.categorySelected}
              />
            ) : (
              <>
                {isHeaderWidget ? (
                  <HeaderMenu
                    showItems={this.showItems}
                    showItem={this.showItem}
                    BannerImageMenu={false}
                    isLocationsHeader={isLocationsHeader}
                    insideWordpress={this.state.insideWordpress}
                    onHandleCategoryClick={this.onHandleCategoryClick}
                    allFunction={this}
                    fullState={this.state}
                    allCategories={
                      showBannerCategory ? bannerCatergories : allCategories
                    }
                    bannerCatergories={bannerCatergories}
                    categorySelected={this.state.categorySelected}
                  />
                ) : null}
              </>
            )}
            {this.state.insideWordpress ? (
              <>
                {!this.state.banner && (
                  <div>
                    <Image
                      src={commonFunctions.concatenateImageWithAPIUrl(
                        this.state.bannerImage
                      )}
                      fluid
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {isMuiltiplyLocationView ? (
                  <>
                    <LocationViewFolder
                      multipltLocationData={this.state.multipltLocationData}
                      insideWordpress={this.state.insideWordpress}
                      bussinessPathName={this.props.global.bussinessUrl}
                      allFunction={this}
                      fullState={this.state}
                    />
                  </>
                ) : (
                  <>
                    {isBannerImage ? (
                      <>
                        {!this.state.banner && (
                          <div>
                            <Image
                              src={commonFunctions.concatenateImageWithAPIUrl(
                                this.state.bannerImage
                              )}
                              fluid
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.BannerImageMenu ? (
                          <BannerImage
                            isLocationSelector={this.state.isLocationSelector}
                            ProductDetailPage={this.state.ProductDetailPage}
                            businesscategoryId={businesscategoryId}
                            businessLocationId={businessLocationId}
                            isShowAvailabletickets={isShowAvailabletickets}
                            businessId={businessId}
                            allFunction={this}
                            fullState={this.state}
                            isShowAvailableItem={isShowAvailableItem}
                            insideWordpress={this.state.insideWordpress}
                            locations={this.state.locations}
                            allCatergoriesBanner={this.allCatergoriesBanner}
                            bannerBusinessLocationId={
                              this.state.bannerBusinessLocationId
                            }
                            individualProductBookNowDetails={{}}
                            isBannerImage={this.state.isBannerImage}
                            getBusinessCategoryWebsiteWidget={
                              this.getBusinessCategoryWebsiteWidget
                            }
                          />
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {this.state.items && (
              <div className="itemsOuter">
                <Grid>
                  <Grid.Column computer={4} tablet={8} mobile={16}>
                    <Form.Dropdown
                      selection
                      fluid
                      className="custom-select"
                      disabled={!this.state.isLocationSelector}
                      options={locationOption}
                      name="ddpLocation"
                      data={"locations"}
                      value={this.state.locations}
                      onChange={this.onHandleChange}
                    ></Form.Dropdown>
                  </Grid.Column>

                  {allCategories.length !== 1 ? (
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form>
                        <Form.Field>
                          {businessCategoryType === 1 &&
                          startDate === "" &&
                          EndDate === "" &&
                          startTime === "" &&
                          endTime === "" ? (
                            <Segment
                              className="textCenter"
                              onClick={this.AddRentalModal}
                            >
                              Select your rental period to check availability of
                              item{" "}
                            </Segment>
                          ) : businessCategoryType !== 1 ? (
                            ""
                          ) : (
                            <p
                              class="selectedDate"
                              onClick={this.AddRentalModal}
                            >
                              {startDate} {startTime}{" "}
                              <Icon
                                className="iconArrowRight"
                                name="arrow right"
                              />
                              {EndDate} {endTime}{" "}
                            </p>
                          )}
                        </Form.Field>
                      </Form>
                    </Grid.Column>
                  ) : null}
                </Grid>
                <>
                  {this.state.items && (
                    <Grid className="customGrid">
                      {api.isApiLoading && (
                        <div>
                          <Dimmer active inverted>
                            <Loader />
                          </Dimmer>
                        </div>
                      )}
                      {allProducts.map((product) => {
                        let productId = product.productId === 0 ? false : true;
                        return (
                          <Grid.Column width={4}>
                            <div
                              className="itemsBox"
                              onClick={(e) =>
                                this.openSubCategoty(e, { product })
                              }
                            >
                              {product.image === "NullImage.png" ? null : (
                                <Image
                                  src={commonFunctions.concatenateImageWithAPIUrl(
                                    product.image
                                  )}
                                  fluid
                                />
                              )}
                              <div className="cart not">
                                {product.type === "Rental" &&
                                product.areAllProductsReserved === 1 ? (
                                  <span className="inventoryItemAvailability">
                                    Item might not be available
                                  </span>
                                ) : product.type === "Rental" &&
                                  product.available ===
                                    "Product may not be available" ? (
                                  <span className="inventoryItemAvailability">
                                    Item might not be available
                                  </span>
                                ) : null}
                                {product.type.indexOf("Retail") > -1 &&
                                product.areAllProductsReserved === 1 ? (
                                  <span className="inventoryItemAvailability">
                                    Out Of Stock.
                                  </span>
                                ) : product.type.indexOf("Retail") > -1 &&
                                  product.retailAvailable !== null &&
                                  product.retailAvailable ===
                                    "Out Of Stock." ? (
                                  <span className="inventoryItemAvailability">
                                    Out Of Stock.
                                  </span>
                                ) : null}

                                <div>
                                  <p className="orange" title={product.name}>
                                    {product.name}
                                  </p>
                                </div>
                                <div className="cartRight">
                                  {productId && (
                                    <Button
                                      onClick={() => this.AddCartModal(product)}
                                      className="primaryBtn"
                                    >
                                      Book
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid.Column>
                        );
                      })}
                    </Grid>
                  )}
                </>
              </div>
            )}
            {this.state.nocategory && (
              <Grid.Column width={16} className="categoryErrorOuter">
                <div className="categoryError">
                  <h1>No items found -Try to open other category </h1>
                  <Image className="emptyError" src={empty}></Image>
                </div>
              </Grid.Column>
            )}
            {this.state.locatoion && (
              <div className="itemsOuter location">
                <Grid className="customGrid">
                  {api.isApiLoading && (
                    <div>
                      <Dimmer active inverted>
                        <Loader />
                      </Dimmer>
                    </div>
                  )}
                  {/* Common Component LocatioFroHeader */}
                  {this.state.insideWordpress ? (
                    <LocationForHeader
                      openInventory={this.openInventory}
                      LOCATIONFORBUSINESS={LOCATIONFORBUSINESS}
                    />
                  ) : (
                    <>
                      {isLocationsHeader ? (
                        <LocationForHeader
                          openInventory={this.openInventory}
                          LOCATIONFORBUSINESS={LOCATIONFORBUSINESS}
                        />
                      ) : null}
                    </>
                  )}
                </Grid>
              </div>
            )}
            {cartStatus && (
              <AddCartModal
                bookNow={bookNow}
                history={this.state.historyCall}
                isShowAvailableItem={isShowAvailableItem}
                isShowAvailabletickets={isShowAvailabletickets}
                openModal={cartStatus}
                closeModal={this.AddCartModal}
                addItem={addItem}
                allFuction={this}
                fullState={this.state}
                startTime={shopstartTime}
                startAm={shopstartAm}
                allProductImages={allProductImages}
                productDetail={productDetail}
                allProducts={allProducts}
              />
            )}
            {this.state.ProductDetailPage && (
              <ProductDetailPage
                wordpressModal={false}
                openaddtocart={this.openaddtocart}
                bookNow={bookNow}
                history={this.state.historyCall}
                isShowAvailableItem={isShowAvailableItem}
                isShowAvailabletickets={isShowAvailabletickets}
                backButtonInWidget={true}
                backtoitem={this.backtoitem}
                allProducts={allProducts}
                addItem={addItem}
                fullState={this.state}
                allImages={allProductImages}
                productDetail={productDetail}
                allFuction={this}
                itemId={this.state.itemId}
              />
            )}
            <AddCartPopUp />
            {this.state.myCartPage && (
              <MyCartPage
                fullState={this.state}
                history={this.state.historyCall}
                bookNow={true}
                allFuction={this}
              />
            )}
            <RentalPeriodModal
              openaddtocart={this.openaddtocart}
              bookNow={bookNow}
              openModal={this.state.rentalStatus}
              closeModal={this.AddRentalModal}
              availabilitySchedule={this.state.availabilitySchedule}
              addItem={this.props.addItem}
              allState={this.state}
              allFuction={this}
            />
          </div>
        ) : null}
        {bookNow && (
          <Button
            className="primaryBtn bookNowBtn"
            onClick={
              this.state.isMuiltiplyLocationWordpressView
                ? this.bookLocationModal
                : this.bookNowModal
            }
          >
            Book Now
          </Button>
        )}

        {isbookNowFunction ? (
          <Button
            className="primaryBtn bookNowBtn"
            style={{ opacity: 0 }}
            id="rehntitBookNow"
            onClick={
              this.state.isMuiltiplyLocationWordpressView
                ? this.bookLocationModal
                : this.bookNowModal
            }
          ></Button>
        ) : null}
        {/* newly added */}
        {isMuiltiplyLocationWordpressView ? (
          <>
            {this.state.locationViewWordpress && (
              <LocationViewWordpressFolder
                multipltLocationData={this.state.multipltLocationData}
                openModal={this.state.locationViewWordpress}
                closeModal={this.closeModalWordpress}
                allFuction={this}
                fullState={this.state}
                bussinessPathName={this.state.bussinessPathName}
              />
            )}
          </>
        ) : (
          <>
            {this.state.bookWordpressitem && (
              <PluginModal
                bookNow={bookNow}
                openaddtocart={this.openaddtocart}
                ProductDetailPage={this.state.ProductDetailPage}
                insideWordpress={this.state.insideWordpress}
                isLocationSelector={this.state.isLocationSelector}
                history={this.state.historyCall}
                isShowAvailableItem={isShowAvailableItem}
                isShowAvailabletickets={isShowAvailabletickets}
                openModal={this.state.bookWordpressitem}
                businesscategoryId={businesscategoryId}
                businessLocationId={businessLocationId}
                businessId={businessId}
                allFuction={this}
                locations={this.state.locations}
                isToOpenItems={this.state.isToOpenItems}
                fullState={this.state}
                individualProductBookNowDetails={{}}
                closeModal={this.bookNowCloseModal}
                isBannerImage={this.state.isBannerImage}
              />
            )}
          </>
        )}

        {/*  individual item add to cart modal */}
        {this.state.newCartStatus && (
          <AddCartModal
            openaddtocart={this.openaddtocart}
            bookNow={bookNow}
            fullstate={this.state}
            history={this.state.historyCall}
            openModal={this.state.newCartStatus}
            closeModal={this.onCloseModal}
            addItem={addItem}
            fullState={this.state}
            onDirectlyOpenCheckout={this.onDirectlyOpenCheckout}
            individualItem={true}
            allFuction={this}
          />
        )}
        {/* individual item direct checkout modal */}
        {directWordpressCheckout && (
          <CheckoutModal
            openModal={directWordpressCheckout}
            closeModal={this.onCloseWordpressDirectCheckout}
            individualItem={true}
            allFunction={this}
            fullState={this.state}
            backToWordpressModal={this.backToWordpressModal}
            closeWordpressModal={this.closeWordpressModal}
          />
        )}
        {addToCartWordpressCheckout && (
          <AddToCartModal
            openModal={addToCartWordpressCheckout}
            closeModal={this.openaddtocart}
            individualItem={true}
            allFunction={this}
            fullState={this.state}
            backToWordpressModal={this.backToWordpressModal}
            closeWordpressModal={this.closeWordpressModal}
          />
        )}
        {this.state.showlocationViewData && (
          <LocationViewData
            allFuction={this}
            fullState={this.state}
            bookNow={bookNow}
            openaddtocart={this.openaddtocart}
            openModal={this.state.showlocationViewData}
            isLocationSelector={this.state.isLocationSelector}
            ProductDetailPage={this.state.ProductDetailPage}
            businesscategoryId={businesscategoryId}
            businessLocationId={businessLocationId}
            isShowAvailabletickets={isShowAvailabletickets}
            businessId={businessId}
            bussinessPathUrl={this.state.bussinessPathUrl}
            locations={this.state.locations}
            showClassInLocation={true}
            insideWordpress={this.state.insideWordpress}
            isShowAvailableItem={isShowAvailableItem}
            bannerBusinessLocationId={this.state.locationViewId}
            closeModal={this.closeModalLocation}
            closeWordpressModal={this.closeWordpressModal}
            isBannerImage={this.state.isBannerImage}
          />
        )}
        {this.state.locationView && (
          <LocationViewWordpressFolder
            allFuction={this}
            fullState={this.state}
            closeWordpressModal={this.closeWordpressModal}
            multipltLocationData={this.state.multipltLocationData}
            openModal={this.state.locationView}
            closeModal={this.bookNowLocationModal}
            bussinessPathName={this.props.global.bussinessUrl}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      StoreBussinessUrl: bindActionCreators(
        actions.StoreBussinessUrl,
        dispatch
      ),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeSkipOptionData: bindActionCreators(
        actions.storeSkipOptionData,
        dispatch
      ),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeThemeColor: bindActionCreators(actions.storeThemeColor, dispatch),
      storeLocationTimeZone: bindActionCreators(
        actions.storeLocationTimeZone,
        dispatch
      ),
      
      storeShowCount: bindActionCreators(
        actions.storeShowCount,
        dispatch
      ),
      storeInsideWordpress: bindActionCreators(
        actions.storeInsideWordpress,
        dispatch
      ),
      storeWordpressBacktoStop: bindActionCreators(
        actions.storeWordpressBacktoStop,
        dispatch
      ),
      storeParentId: bindActionCreators(actions.storeParentId, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
      storeBussinessLocationId: bindActionCreators(
        actions.storeBussinessLocationId,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      DeleteItemIndividualDetail: bindActionCreators(
        actions.DeleteItemIndividualDetail,
        dispatch
      ),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
