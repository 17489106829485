import React, { Component } from "react";
import {Grid, Image,Icon,Form,Button,Loader,Segment,
  Popup} from "semantic-ui-react";
import AddCartModal from "../../shared/components/organisms/modal/add-cart/index";
import ProductDetailPage from "../home/product-details";
import AddCartPopUp from "./add-cart-popup";
import RentalPeriodModal from "../../shared/components/organisms/modal/rental-period/index";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  withRouter,
  Notifications,
} from "../../shared/functional/global-import";
import { empty } from "../../shared/functional/global-image-import";
import MyCartPage from "../my-cart/my-cart";

class HomeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocationSelector: false,
      bookWordpressitem: false,
      bookNow: true,
      date: "",
      time: "",
      dateTime: "",
      datesRange: "",
      items: false,
      locatoion: false,
      banner: false,
      allCategories: [],
      insideWordpress: false,
      allData: "",
      LOCATIONFORBUSINESS: [],
      bannerImage: "",
      allProducts: [],
      itemPrice: "",
      addItem: "",
      nocategory: false,
      ProductDetailPage: false,
      productDetail: "",
      locations: "",
      categorySelected: "",
      itemId: 0,
      allTags: [],
      parentId: "",
      availabilitySchedule: {
        startDate: "", // start date.
        EndDate: "", // end date.
        startTime: "", // start time
        endTime: "", // end time
      },
      startDate: "",
      EndDate: "",
      startTime: "",
      endTime: "",
      MyCartPage: false,
      nocategories: false,
      isLoading: false,
      previousBussinessId: "",
      isBackToHome: true,
    };
  }

  backtoitem = () => {
    this.setState({ ProductDetailPage: false, items: true });
  };
  onClickIndividualProduct = (e) => {
    this.props.actions.apiCall({
      urls: ["GETCATEGORIESFORBUSINESS"],
      method: "GET",
      data: {
        businessId: "83",
        BusinessLocationId: e.businessLocationId,
        ParentId: e.categoryId,
        BusinessCategoryType: e.businessCategoryType,
        PageNo: 1,
        PageSize: 100,
        startDate: this.state.startDate,
        EndDate: this.state.EndDate,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
      },
      onSuccess: (response) => {
        this.setState({ items: true, locatoion: false, nocategory: false });
        this.setState({ allProducts: response });
        let particularItemClicked =
          this.props?.individualProductBookNowDetails?.productId;
        let newObj1 = {};
        response &&
          response.length > 0 &&
          response.map((singleObj) => {
            if (singleObj.productId === particularItemClicked) {
              newObj1 = singleObj;
            }
            return singleObj;
          });

        this.props.actions.storeParentId(e.categoryId);

        if (response.length === 0) {
          this.setState({ nocategory: true });
        } else {
          this.setState({ locations: response[0]?.businessLocationId });
        }
        this.AddCartModal(newObj1);
      },
    });
  };
  componentDidMount() {
    const {
      individualProductBookNowDetails,
      isItemSpecificBookNow,
      bookNowState,
    } = this.props;
    if (isItemSpecificBookNow) {
      let obj = {
        businessId: bookNowState.businessId,
        businessLocationId: bookNowState.businessLocationId,
        categoryId: individualProductBookNowDetails.categoryId,
        businessCategoryType:
          individualProductBookNowDetails.businesscategoryId,
      };
      setTimeout(() => this.onClickIndividualProduct(obj), 1000);
    } else {
      this.seeAllItems();
    }
    this.setState({
      locations: bookNowState.businessLocationId,
      nocategories: bookNowState.nocategory,
    });
    this.getWidgetLocaion();
    this.getGlobalCode();
  }
  seeAllItems = () => {
    this.setState({
      categorySelected: "",
      isLoading: true,
      previousBussinessId: this.props.bookNowState.businessId,
    });
    this.setState({ items: true, banner: true, ProductDetailPage: false });
    this.props.actions.apiCall({
      urls: ["GETCATEGORIESFORBUSINESS"],
      method: "GET",
      data: {
        businessId: this.props.bookNowState.businessId,
        BusinessLocationId: this.props.bookNowState.businessLocationId,
        BusinessCategoryType: this.props.bookNowState.businessCategoryTypeId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        this.props.actions.storeParentId(null);
        if (response.length === 0) {
          this.setState({
            nocategory: false,
            locations: this.props.bookNowState.businessLocationId,
            isLoading: false,
          });
        }
        this.setState({
          items: true,
          locatoion: false,
          nocategory: true,
          locations: response[0].businessLocationId,
        });
        this.setState({
          allProducts: response,
          businessCategoryType: response[0].businessCategoryType,
        });
      },
      onFinally: () => {
        this.setState({ isLoading: false });
      },
    });
  };

  getGlobalCode = () => {
    this.props.actions.apiCall({
      urls: ["GLOBALCODE"],
      method: "GET",
      data: {
        CategoryName: "State",
        GlobalCodeId: -1,
      },
      onSuccess: (response) => {
        const globalCode = response.map((singleData) => {
          return { text: singleData.codeName, value: singleData.globalCodeId };
        });
        this.props.actions.storeGlobalCodes(globalCode);
      },
      showNotification: false,
    });
  };
  getWidgetLocaion() {
    this.props.actions.apiCall({
      urls: ["GETBUSINESSLOCATION"],
      method: "GET",
      data: {
        InWebsiteWidget: 1,
        BusinessId: this.props.global.addBussinessId,
        BusinessLocationId: -1,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        let locationData = response.map((post) => {
          return {
            text: post.businessLocationName,
            value: post.businessLocationId,
            id: post.businessLocationId,
          };
        });
        this.setState({ locationOption: locationData });
      },
    });
  }

  onMoreClick(e, { product }) {
    if (
      product.businessCategoryType === 1 &&
      this.state.startDate !== "" &&
      this.state.EndDate !== "" &&
      this.state.startTime !== "" &&
      this.state.endTime !== ""
    ) {
      this.setState({ categorySelected: "" });
      this.setState({ items: true, banner: true, ProductDetailPage: false });
      this.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: this.state.businessId,
          BusinessLocationId: product.businessLocationId,
          startDate: this.state.startDate,
          EndDate: this.state.EndDate,
          startTime: this.state.startTime,
          endTime: this.state.endTime,
          ParentId: product.parentId,
          BusinessCategoryType: product.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
        },
        onSuccess: (response) => {
          this.props.actions.storeParentId(null);
          this.setState({
            items: true,
            locatoion: false,
            nocategory: false,
            locations: response[0].businessLocationId,
          });
          this.setState({
            allProducts: response,
            businessCategoryType: response[0].businessCategoryType,
          });
          if (response.length === 0) {
            this.setState({ nocategory: true });
          }
        },
      });
    } else {
      this.setState({ categorySelected: "" });
      this.setState({ items: true, banner: true, ProductDetailPage: false });
      this.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: this.state.businessId,
          BusinessLocationId: product.businessLocationId,
          ParentId: product.parentId,
          BusinessCategoryType: product.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
        },
        onSuccess: (response) => {
          this.props.actions.storeParentId(null);
          this.setState({
            items: true,
            locatoion: false,
            nocategory: false,
            locations: response[0].businessLocationId,
          });
          this.setState({
            allProducts: response,
            businessCategoryType: response[0].businessCategoryType,
          });
          if (response.length === 0) {
            this.setState({ nocategory: true });
          }
        },
      });
    }
  }

  openSubCategoty = (e, { product }) => {
    if (
      product.productId !== 0 &&
      e.target.className !== "cart plus link icon"
    ) {
      this.setState({
        items: false,
        ProductDetailPage: true,
        productDetail: product,
      });
    }
    // else if (
    //   product.productId === 0 &&
    //   e.target.className === "ui fluid image"
    // ) {
    else {
      this.GETCATEGORIESFORBUSINESS(product);
    }
  };

  GETCATEGORIESFORBUSINESS = (e) => {
    if (this.state.businessCategoryType !== undefined) {
      this.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: e.businessId,
          BusinessLocationId: e.businessLocationId,
          ParentId: e.categoryId,
          BusinessCategoryType: e.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
          startDate: this.state.startDate,
          EndDate: this.state.EndDate,
          startTime: this.state.startTime,
          endTime: this.state.endTime,
        },
        onSuccess: (response) => {
          this.setState({ items: true, locatoion: false, nocategory: false });
          this.setState({ allProducts: response });
          this.props.actions.storeParentId(e.categoryId);

          if (response.length === 0) {
            this.setState({ nocategory: true });
          } else {
            this.setState({ locations: response[0].businessLocationId });
          }
        },
      });
    } else {
      this.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: this.state.businessId,
          BusinessLocationId: e.businessLocationId,
          ParentId: e.categoryId,
          BusinessCategoryType: e.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
          startDate: this.state.startDate,
          EndDate: this.state.EndDate,
          startTime: this.state.startTime,
          endTime: this.state.endTime,
        },
        onSuccess: (response) => {
          this.setState({ items: true, locatoion: false, nocategory: false });
          this.setState({ allProducts: response });
          this.props.actions.storeParentId(e.categoryId);

          if (response.length === 0) {
            this.setState({ nocategory: true });
          } else {
            this.setState({ locations: response[0].businessLocationId });
          }
        },
      });
    }
    this.setState({ isBackToHome: false });
  };

  getBusinessUrlForcodEembedded = (e) => {
    let bussinessPathName = document
      .getElementById("rehntit-website-widget")
      .getAttribute("bussinesId");
    let bussinessLocationId = document
      .getElementById("rehntit-website-widget")
      .getAttribute("bussinesslocationid");
    bussinessLocationId =
      bussinessLocationId === null ? 0 : bussinessLocationId;
    this.props.actions.apiCall({
      urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
      method: "GET",
      data: {
        WebsiteUrl:
          bussinessPathName === ""
            ? this.props.global.bussinessUrl
            : bussinessPathName,
        BusinesslocationId: bussinessLocationId,
      },
      onSuccess: (response) => {
        if (response[0].errorCode !== "500") {
          const allCartItems = this.props.global.allProductDetail.products;
          const cartBusinessIds = allCartItems.map((item) => {
            return item.addItem.businessId;
          });
          const currentBusinessId = response[0].businessId;
          const isSameId = cartBusinessIds.every(
            (currentValue) => currentValue === currentBusinessId
          );
          if (!isSameId) {
            this.props.actions.DeleteAllItemDetail();
          }
          let themeColor = response[0].color;
          const root = document.documentElement;
          root?.style.setProperty("--main-color", themeColor);
          this.props.actions.storeLocationTimeZone(response[0].locationTimeZone);
          this.props.actions.storeThemeColor(response[0].color);
          this.props.actions.storeBussinessId(response[0].businessId);
          this.props.actions.storeBussinessDetail(response[0].image);
          this.setState({
            businessId: response[0].businessId,
            isLocationSelector: response[0].isLocationSelector,
            // insideWordpress: true,
          });
          this.setState({ allCategories: JSON.parse(response[0].categories) });
          this.props.actions.SHOWHEADERWEBSITE(response[0].widgetHeader);
          // this.props.actions.storeShowCount(true);
          this.setState({ banner: true });
          this.setState({
            allTags:
              JSON.parse(response[0].categories)?.[0].Categories === null
                ? []
                : JSON.parse(
                    JSON.parse(response[0].categories)[0].Categories[0].Tags
                  ),
          });
          this.getWidgetLocaion();
        } else {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: response[0].responseMessage,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
      },
    });
  };

  getLocationForBusiness = () => {
    this.props.actions.apiCall({
      urls: ["GETLOCATIONFORBUSINESS"],
      method: "GET",
      data: {
        BusinessId: this.state.businessId,
      },
      onSuccess: (response) => {
        this.setState({ LOCATIONFORBUSINESS: response });
        this.getWidgetLocaion();
      },
    });
  };
  bookNowModal = () => {
    this.setState({ bookWordpressitem: !this.state.bookWordpressitem });
  };

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  AddCartModal = (e) => {
    this.setState({
      cartStatus: !this.state.cartStatus,
      addItem: e === undefined ? "" : e,
      items: true,
      ProductDetailPage: false,
    });
  };
  onCloseModal = () => {
    this.setState({
      cartStatus: false,
      addItem: "",
      items: false,
      ProductDetailPage: false,
    });
  };
  onHandleChange = (e, { name, value, type, checked, data }) => {
    this.props.backtochangeCategory(value, true);
    this.props.allCatergoriesBanner(value);
  };
  BacktoCategories = (e) => {
    // this.props.backtochangeCategory(e, true);
    if (this.state.isBackToHome) {
      this.props.backtochangeCategory(e, true);
      this.setState({ isBackToHome: false });
    } else {
      this.GetBackToCategory(this.state.previousBussinessId);
    }
  };

  GetBackToCategory(e) {
    this.props.actions.apiCall({
      urls: ["GETCATEGORIESFORBUSINESS"],
      method: "GET",
      data: {
        businessId: e,
        BusinessLocationId: this.props.bookNowState.businessLocationId,
        BusinessCategoryType: this.props.bookNowState.businessCategoryTypeId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        this.props.actions.storeParentId(null);
        if (response.length === 0) {
          this.setState({
            nocategory: false,
            locations: this.props.bookNowState.businessLocationId,
            previousBussinessId: this.props.bookNowState.businessId,
          });
        }
        this.setState({
          items: true,
          locatoion: false,
          nocategory: true,
          locations: response[0].businessLocationId,
          isLoading: false,
        });
        this.setState({
          allProducts: response,
          businessCategoryType: response[0].businessCategoryType,
          isBackToHome: true,
        });
      },
    });
  }
  onHandleCategoryClick = (selected) => {
    this.setState({ categorySelected: selected });
  };

  openaddtocart = () => {
    //  modal checkout btn
    this.setState({
      items: false,
      MyCartPage: true,
      ProductDetailPage: false,
    });
    this.props.allFuction.setState({
      isBookNowWidget: false,
      isHeaderWidget: false,
      showCountCart: false,
    });
  };

  wordpressAddToCart = () => {
    this.setState({
      cartStatus: false,
      addItem: "",
      items: false,
      MyCartPage: true,
      ProductDetailPage: false,
    });
  };

  backToHomePage = () => {
    this.setState({ items: true, MyCartPage: false });
  };
  AddRentalModal = () => {
    this.setState({
      availabilitySchedule: {
        ...this.state.availabilitySchedule,
        startDate: "",
        EndDate: "",
        startTime: "",
        endTime: "",
      },
    });
    this.setState({ rentalStatus: !this.state.rentalStatus });
  };

  render() {
    const {
      api,
      closeWordpressModal,
      isShowAvailabletickets,
      isShowAvailableItem,
    } = this.props;
    const {
      allProducts,
      addItem,
      productDetail,
      allProductImages,
      locationOption,
      cartStatus,
      businessCategoryType,
      startDate,
      EndDate,
      startTime,
      endTime,
      isHeaderWidget,
      isBookNowWidget,
    } = this.state;
    const isLocationSelcted = this.props.isLocationSelector;                
    return (
      <>
        {this.state.bookNow && (
          <div className="homePage bookNowHomePage homePageAll">
            {!this.state.banner && (
              <div>
                <Image
                  src={commonFunctions.concatenateImageWithAPIUrl(
                    this.state.bannerImage
                  )}
                  fluid
                />
              </div>
            )}
            {this.state.items && (
              <div className="itemsOuter">
                <Grid>
                  {this.props.backButtonCatergory && (
                    <Grid.Column
                      computer={15}
                      tablet={15}
                      mobile={15}
                      className="flex align-items-center"
                    >
                      <Popup
                        content="Back to Categories"
                        position="right center"
                        trigger={
                          <Icon
                            onClick={() => {
                              this.BacktoCategories(this.state.locations);
                            }}
                            name="arrow alternate circle left outline"
                            size="big"
                          />
                        }
                      />

                      <Form.Dropdown
                        selection
                        fluid
                        disabled={!isLocationSelcted}
                        className="custom-select smWidth"
                        options={locationOption}
                        name="ddpLocation"
                        data={"locations"}
                        value={this.state.locations}
                        onChange={this.onHandleChange}
                      ></Form.Dropdown>
                    </Grid.Column>
                  )}
                
                  {allProducts.length !== 1 ? (
                    <Grid.Column
                      computer={8}
                      tablet={8}
                      mobile={16}
                      className="centered"
                    >
                      <Form>
                        <Form.Field>
                          {businessCategoryType === 1 &&
                          startDate === "" &&
                          EndDate === "" &&
                          startTime === "" &&
                          endTime === "" ? (
                            <Segment
                              className="textCenter"
                              onClick={this.AddRentalModal}
                            >
                              Select your rental period to check availability of
                              item{" "}
                            </Segment>
                          ) : businessCategoryType !== 1 ? (
                            ""
                          ) : (
                            <p
                              class="selectedDate"
                              onClick={this.AddRentalModal}
                            >
                              {startDate} {startTime}{" "}
                              <Icon
                                className="iconArrowRight"
                                name="arrow right"
                              />
                              {EndDate} {endTime}{" "}
                            </p>
                          )}
                        </Form.Field>
                      </Form>
                    </Grid.Column>
                  ) : null}
                </Grid>
                {this.state.items && (
                  <Grid className="customGrid">
                    {api.isApiLoading && (
                      <div>
                        {/* <Dimmer active inverted> */}
                        <Loader />
                        {/* </Dimmer> */}
                      </div>
                    )}
                    {allProducts.map((product) => {
                      let productId = product.productId === 0 ? false : true;

                      return (
                        <Grid.Column width={4}>
                          <div
                            className="itemsBox"
                            onClick={(e) =>
                              this.openSubCategoty(e, { product })
                            }
                          >
                            {product.image === "NullImage.png" ? null : (
                              <Image
                                src={commonFunctions.concatenateImageWithAPIUrl(
                                  product.image
                                )}
                                fluid
                              />
                            )}
                            <div className="cart not">
                              {product.type === "Rental" &&
                              product.areAllProductsReserved === 1 ? (
                                <span className="inventoryItemAvailability">
                                  Item might not be available
                                </span>
                              ) : product.type === "Rental" &&
                                product.available ===
                                  "Product may not be available" ? (
                                <span className="inventoryItemAvailability">
                                  Item might not be available
                                </span>
                              ) : null}
                              {product.type.indexOf("Retail") > -1 &&
                              product.areAllProductsReserved === 1 ? (
                                <span className="inventoryItemAvailability">
                                  Out Of Stock.
                                </span>
                              ) : product.type.indexOf("Retail") > -1 &&
                                product.retailAvailable !== null &&
                                product.retailAvailable === "Out Of Stock." ? (
                                <span className="inventoryItemAvailability">
                                  Out Of Stock.
                                </span>
                              ) : null}

                              <div
                              // onClick={(e) =>
                              //   this.openSubCategoty(e, { product })
                              // }
                              >
                                <p
                                  className="orange textOverFlowHidden"
                                  title={product.name}
                                >
                                  {product.name}
                                </p>
                              </div>
                              <div className="cartRight">
                                {productId && (
                                  <Button
                                    className="primaryBtn"
                                    onClick={() => this.AddCartModal(product)}
                                  >
                                    Book
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Grid.Column>
                      );
                    })}

                    {allProducts.length === 0 && (
                      <Grid.Column width={16} className="categoryErrorOuter">
                        {this.state.isLoading ? (
                          <Loader />
                        ) : (
                          <div className="categoryError">
                            <h1>No items found -Try to open other category </h1>
                            <Image className="emptyError" src={empty}></Image>
                          </div>
                        )}
                      </Grid.Column>
                    )}
                  </Grid>
                )}
              </div>
            )}
            {cartStatus && (
              <AddCartModal
                addItem={addItem}
                fullstate={this.props.fullstate}
                allFuction={this.props.allFuction}
                openaddtocart={this.openaddtocart}
                isShowAvailableItem={isShowAvailableItem}
                isShowAvailabletickets={isShowAvailabletickets}
                wordpressAddToCart={this.wordpressAddToCart}
                history={this.props.history}
                openModal={cartStatus}
                allFunctionSet={this}
                closeModal={this.AddCartModal}
                showCountCart={this.props.showCountCart}
                closeModalhome={this.props.closeModalhome}
                insideWordpress={this.props.insideWordpress}
                allProductImages={allProductImages}
                productDetail={productDetail}
                allProducts={allProducts}
                wordpressModal={true}
              />
            )}
            {this.state.MyCartPage && (
              <MyCartPage
                history={this.props.history}
                bookNow={true}
                backTocatergoryWordpress={this.props.backTocatergoryWordpress}
                backtochangeCategory={this.props.backtochangeCategory}
                fullstate={this.props.fullstate}
                allFunction={this.props.allFuction}
                locations={this.state.locations}
                closeModalhome={this.props.closeModalhome}
                closeWordpressModal={closeWordpressModal}
                insideWordpress={this.props.insideWordpress}
              />
            )}
            {this.state.ProductDetailPage && (
              <ProductDetailPage
                wordpressModal={true}
                history={this.props.history}
                backButtonInWidget={false}
                allProducts={allProducts}
                bookNow={this.state.bookNow}
                backtoitem={this.backtoitem}
                addItem={addItem}
                isShowAvailabletickets={isShowAvailabletickets}
                allImages={allProductImages}
                closeModalhome={this.props.closeModalhome}
                productDetail={productDetail}
                fullstate={this.props.fullstate}
                allFuction={this.props.allFuction}
                itemId={this.state.itemId}
                closeWordpressModal={closeWordpressModal}
                insideWordpress={this.props.insideWordpress}
                isHeaderWidget={isHeaderWidget}
                isBookNowWidget={isBookNowWidget}
                modalProp={this.props.modalProp}
                getBusinessCategoryWebsiteWidget={
                  this.props.getBusinessCategoryWebsiteWidget
                }
              />
            )}
            {!this.state.MyCartPage && (
              <AddCartPopUp
                bookNow={true}
                history={this.props.history}
                fullstate={this.props.fullstate}
                allFuction={this.props.allFuction}
                showCountCart={this.props.showCountCart}
                openaddtocart={this.openaddtocart}
                closeModalhome={this.props.closeModalhome}
                backtochangeCategory={this.backtochangeCategory}
                insideWordpress={this.props.insideWordpress}
              />
            )}
            <RentalPeriodModal
              openModal={this.state.rentalStatus}
              closeModal={this.AddRentalModal}
              availabilitySchedule={this.state.availabilitySchedule}
              addItem={this.props.addItem}
              allState={this.state}
              allFuction={this}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      StoreBussinessUrl: bindActionCreators(
        actions.StoreBussinessUrl,
        dispatch
      ),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeShowCount: bindActionCreators(
        actions.storeShowCount,
        dispatch
      ),

      storeLocationTimeZone: bindActionCreators(actions.storeLocationTimeZone, dispatch),
      storeThemeColor: bindActionCreators(actions.storeThemeColor, dispatch),
      storeParentId: bindActionCreators(actions.storeParentId, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
      storeBussinessLocationId: bindActionCreators(
        actions.storeBussinessLocationId,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeModal)
);
