import React from "react";
import PropTypes from "prop-types";
import { Grid, Input, Header, Icon, Form, Popup ,Dimmer,Loader} from "semantic-ui-react";
import { commonFunctions } from "../../../functional/global-import";
const ActivityBookTicket = ({
  tickets,
  countHandleMinus,
  countHandlePlus,
  activityValidations,
  handleChangeInput,
  loaderActivityData
}) => {
  activityValidations.purgeFields();
                    
  return (
    
    <>
    {loaderActivityData && (
      <Dimmer active inverted>
        {" "}
        <Loader size="small"></Loader>{" "}
      </Dimmer>
    )}
      <Header as="h3">Select Tickets</Header>
      <Grid>
        <Grid.Column width={5}>
          <h4>No. of Tickets</h4>
        </Grid.Column>
        <Grid.Column width={5}>
          <h4>
            Ticket & Prices 
          </h4>
          <h4 class="ticket-prices">
            <small className="perTicket">(Per Ticket)</small>{" "}
          </h4>
        </Grid.Column>
        <Grid.Column width={6}>
          <h4>Descriptions</h4>
        </Grid.Column>
      </Grid>
      {tickets.map((item, index) => {
        let ticketCount = 0;
        return (
          <Grid>
            <Grid.Column width={5}>
              <Form>
                <Form.Field className="add-numbers">
                  <Icon
                    name="minus"
                    className="orange-button"
                    onClick={() => countHandleMinus(index, "minus")}
                  ></Icon>
                  <Input
                    className="CountnumberOfTickets"
                    value={item.numberOfTickets}
                    name="numberOfTickets"
                    fluid
                  />
                  <Icon
                    name="plus"
                    className="orange-button plus-btn"
                    onClick={() => countHandlePlus(index, "plus")}
                  ></Icon>
                </Form.Field>
              </Form>
            </Grid.Column>

            <Grid.Column width={5}>
              {item.title} | {commonFunctions.isNumberInteger(item.price)}
            </Grid.Column>
            <Grid.Column width={6}>
              <Popup
                content={item.description}
                trigger={
                  <span className="vertical-ellipses">{item.description}</span>
                }
              />

              {/* <div title={item.description} className="vertical-ellipses">
            
          </div> */}
            </Grid.Column>
            <Grid.Row>
              {item.numberOfTickets > 0 &&
                item.customFields &&
                item.customFields?.length > 0 &&
                item.customFields.map((customObj, customIndex) => {
                  const numberOfCustomFields = item.customField.length;
                  const showTicketCustomFieldBox =
                    customIndex % numberOfCustomFields === 0;
                  if (showTicketCustomFieldBox) {
                    ticketCount += 1;
                  }
                  return (
                    <>
                      {showTicketCustomFieldBox && (
                        <Grid.Column width={16}>
                          <div className="NewRow">
                            <span> Ticket {ticketCount}</span>
                          </div>
                        </Grid.Column>
                      )}
                      <Grid.Column
                        computer={8}
                        tablet={16}
                        className="activity-select"
                        style={{ marginBottom: "15px" }}
                      >
                        <Input
                          width={10}
                          placeholder={customObj.customDescriptions}
                          name={`${customObj.customField} ${customIndex}`}
                          id={customObj.customFieldDetailId}
                          fluid
                          onChange={(event, { name, value, id }) =>
                            handleChangeInput(
                              event,
                              { name, value, id },
                              index,
                              customObj,
                              customIndex
                            )
                          }
                          value={customObj?.customAnswer}
                          error={
                            customObj.customRequired
                              ? activityValidations?.message(
                                  "text",
                                  customObj?.customAnswer,
                                  "required"
                                )
                              : ""
                          }
                        />
                        {customObj.customRequired && (
                          <span className="estrickSign">*</span>
                        )}
                      </Grid.Column>
                    </>
                  );
                })}
            </Grid.Row>
          </Grid>
        );
      })}
    </>
  );
};

ActivityBookTicket.propTypes = {
  tickets: PropTypes.isRequired,
  countHandleMinus: PropTypes.func.isRequired,
  countHandlePlus: PropTypes.func.isRequired,
  activityValidations: PropTypes.func.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  loaderActivityData :PropTypes.func.isRequired
};

export default ActivityBookTicket;
