import React, { Component, Fragment } from "react";
import {
  Modal,
  Grid,
  Header,
  Dimmer,
  Loader,
  Image,
  Container,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  withRouter,
  Notifications,
} from "../../shared/functional/global-import";
import HomeModal from "../../pages/home/modal-home";
class BannerImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIndividualItemClickec: false,
      individualProductLoader: false,
      isItemSpecificBookNow: false,
      HomePage: false,
      banner: false,
      allCategories: [],
      allData: "",
      businessLocationId: "",
      LOCATIONFORBUSINESS: [],
      bannerImage: "",
      allProducts: [],
      itemPrice: "",
      businessCategoryTypeId: "",
      addItem: "",
      backButtonCatergory: true,
      nocategory: false,
      ProductDetailPage: false,
      productDetail: "",
      locations: "",
      categorySelected: "",
      allCatergory: false,
      itemId: 0,
      allTags: [],
      parentId: "",
      allBannerCategories : []
    };
  }

  componentDidMount = () => {
    this.getBusinessCategoryWebsiteWidget();
  };
  onIndividualItemFunction = () => {
    let individualProductBookNowDetails =
      this?.props?.individualProductBookNowDetails;
    if (individualProductBookNowDetails) {
      if (individualProductBookNowDetails.productId) {
        this.setState({ individualProductLoader: true });
        let obj = {
          businessCategoryTypeId:
            individualProductBookNowDetails.businesscategoryId,
        };
        this.setState({ isItemSpecificBookNow: true });
        setTimeout(() => this?.onBusinessClick(obj), 2000);
      }
    }
  };

  backtochangeCategory = (e, value) => {
    this.setState(
      {
        HomePage: false,
        isItemSpecificBookNow: false,
        isIndividualItemClickec: value,
      },
      () => this.getBusinessCategoryWebsiteWidget(e)
    );
  };
  getBusinessCategoryWebsiteWidget = (e) => {
    this.props.actions.apiCall({
      urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
      method: "GET",
      data: {
        WebsiteUrl: this.props.global.bussinessUrl,
        BusinesslocationId:
          e !== undefined ? e : this.props.bannerBusinessLocationId,
      },
      onSuccess: (response) => {
          
        if (response[0].errorCode !== "500") {
          const allCartItems = this.props.global.allProductDetail.products;
          const allCategorShow = JSON.parse(response[0].categories).filter(
            (item) => item.Categories != null
            );
            this.setState({allBannerCategories : allCategorShow})
          const cartBusinessIds = allCartItems.map((item) => {
            return item.addItem.businessId;
          });
          const currentBusinessId = response[0].businessId;
          const isSameId = cartBusinessIds.every(
            (currentValue) => currentValue === currentBusinessId
          );
          if (!isSameId) {
            this.props.actions.DeleteAllItemDetail();
          }
          let themeColor = response[0].color;
          this.setState({ businessLocationId: response[0].businessLocationId });
          const root = document.documentElement;
          root?.style.setProperty("--main-color", themeColor);
          this.props.actions.storeThemeColor(response[0].color);
          this.props.actions.storeLocationTimeZone(response[0].locationTimeZone);
          this.props.actions.storeBussinessId(response[0].businessId);
          this.props.actions.storeBussinessDetail(response[0].image);
          this.setState({
            svgImages: JSON.parse(response[0].svgImages),
            color: response[0].color,
          });
          this.setState({
            businessId: response[0].businessId,
            isLocationSelector: response[0].isLocationSelector,
          });
      
          
          if (this.props.isBannerImage ? null : allCategorShow.length === 1) {
            const singleCategory = allCategorShow[0];
            this.setState({
              businessCategoryType: singleCategory.businessCategoryTypeId,
              nocategory: false,
              HomePage: true,
              businessCategoryTypeId: singleCategory.businessCategoryTypeId,
              individualProductLoader: false,
              allCatergory: false,
              backButtonCatergory: true,
            });
          } else if (!allCategorShow.length) {
            this.setState({
              nocategory: false,
              HomePage: true,
              allCatergory: false,
              backButtonCatergory: true,
            });
          } else {
            this.setState({
              allCategories: allCategorShow,
              allCatergory: true,
              backButtonCatergory: true,
            });
          }
          this.props.actions.SHOWHEADERWEBSITE(response[0].widgetHeader);
          // this.props.actions.storeShowCount(true);
          this.setState({ banner: true, nocategory: true });
          this.setState({
            allTags:
              JSON.parse(response[0].categories)?.[0].Categories === null
                ? []
                : JSON.parse(
                    JSON.parse(response[0].categories)[0].Categories[0].Tags
                  ),
          });
          // this.getWidgetLocaion();
        } else {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: response[0].responseMessage,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
        !this.state.isIndividualItemClickec && this.onIndividualItemFunction();
      },
    });
  };

  onBusinessClick = (addBusinessDetail) => {
    this.setState({
      businessCategoryType: addBusinessDetail.businessCategoryTypeId,
      nocategory: false,
      HomePage: true,
      businessCategoryTypeId: addBusinessDetail.businessCategoryTypeId,
      individualProductLoader: false,
    });
  };

  bookNowModal = () => {
    this.setState({ HomePage: false });
  };
  render() {
    const allCatergory = this.state.allBannerCategories
    const { individualProductBookNowDetails } = this.props;
    return (
      <div>
        {this.state.individualProductLoader && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Modal.Description>
          <div className={this.state.HomePage ? "" : "orderPlacedBanner"}>
            <Container fluid>
              {this.state.nocategory && (
                <>
                  {this.state.allCatergory && (
                    <Grid className="">
                      {this.state.allCategories.map(
                        (addBusinessDetail, index) => {
                          return (
                            <>
                              <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={4}
                                textAlign="center"
                                key={index}
                              >
                                <div
                                  className="orderPlacedItem"
                                  onClick={() =>
                                    this.onBusinessClick(addBusinessDetail)
                                  }
                                >
                                  <div className="cmn-shad">
                                    <Header as="h3" textAlign="center">
                                      {addBusinessDetail.catregoryCodeName ===
                                      "Season Pass"
                                        ? "Passes"
                                        : addBusinessDetail.catregoryCodeName}
                                    </Header>
                                    <Image
                                      src={commonFunctions.concatenateImageWithAPIUrl(
                                        addBusinessDetail.Image
                                      )}
                                      fluid
                                    />
                                  </div>
                                </div>
                              </Grid.Column>
                            </>
                          );
                        }
                      )}
                    </Grid>
                  )}
                </>
              )}
              {this.state.HomePage && (
                <HomeModal
                  history={this.props.history}
                  individualProductBookNowDetails={
                    individualProductBookNowDetails
                  }
                  fullstate={this.props.fullState}
                  showCountCart={this.props.fullState.showCountCart}
                  isShowAvailabletickets={this.props.isShowAvailabletickets}
                  isLocationSelector={this.props.isLocationSelector}
                  backButtonCatergory={this.state.backButtonCatergory}
                  allCatergory={this.state.allCatergory}
                  isItemSpecificBookNow={this.state?.isItemSpecificBookNow}
                  openModal={this.state.HomePage}
                  insideWordpress={this.props.insideWordpress}
                  isShowAvailableItem={this.props.isShowAvailableItem}
                  backtochangeCategory={this.backtochangeCategory}
                  allCatergoriesBanner={this.props.allCatergoriesBanner}
                  allCategories = {allCatergory}
                  closeModal={this.bookNowModal}
                  bookNowState={this.state}
                  allFuction={this.props.allFunction}
                  closeWordpressModal={this.props.closeModal}
                  getBusinessCategoryWebsiteWidget={this.props.getBusinessCategoryWebsiteWidget}
                />
              )}
            </Container>
          </div>
        </Modal.Description>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      StoreBussinessUrl: bindActionCreators(
        actions.StoreBussinessUrl,
        dispatch
      ),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeLocationTimeZone: bindActionCreators(actions.storeLocationTimeZone, dispatch),
      storeThemeColor: bindActionCreators(actions.storeThemeColor, dispatch),
      storeParentId: bindActionCreators(actions.storeParentId, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
      storeBussinessLocationId: bindActionCreators(
        actions.storeBussinessLocationId,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannerImage)
);
