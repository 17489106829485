import React, { Component } from "react";
import {
  Modal,
  Grid,
  Header,
  Dimmer,
  Loader,
  Image,
  Container,
  Popup,
  Icon,
  Form,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  withRouter,
  Notifications,
} from "../../shared/functional/global-import";
import HomeModal from "../../pages/home/modal-home";
import AddCartPopUp from "./add-cart-popup";
class LocationViewData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIndividualItemClickec: false,
      individualProductLoader: false,
      isItemSpecificBookNow: false,
      HomePage: false,
      banner: false,
      allCategories: [],
      insideWordpress: false,
      allData: "",
      businessLocationId: "",
      LOCATIONFORBUSINESS: [],
      bannerImage: "",
      itemPrice: "",
      businessCategoryTypeId: "",
      addItem: "",
      backButtonCatergory: true,
      nocategory: false,
      ProductDetailPage: false,
      productDetail: "",
      locations: "",
      categorySelected: "",
      allCatergory: false,
      itemId: 0,
      allTags: [],
      parentId: "",
      locationOption: [],
      showLocationDropdown: false,
    };
  }

  componentDidMount = () => {
    this.getBusinessCategoryWebsiteWidget();
    this.getWidgetLocaion();
    this.setState({ locations: this.props.bannerBusinessLocationId });
  };
  onIndividualItemFunction = () => {
    let individualProductBookNowDetails =
      this?.props?.individualProductBookNowDetails;
    if (individualProductBookNowDetails) {
      if (individualProductBookNowDetails.productId) {
        this.setState({ individualProductLoader: true });
        let obj = {
          businessCategoryTypeId:
            individualProductBookNowDetails.businesscategoryId,
        };
        this.setState({ isItemSpecificBookNow: true });
        setTimeout(() => this?.onBusinessClick(obj), 2000);
      }
    }
  };

  backtochangeCategory = (e, value) => {
    this.setState(
      {
        HomePage: false,
        isItemSpecificBookNow: false,
        isIndividualItemClickec: value,
      },
      () => this.getBusinessCategoryWebsiteWidget(e)
    );
  };

  getBusinessCategoryWebsiteWidget = (e) => {
    
    const { bussinessPathUrl } = this.props;
    this.props.actions.apiCall({
      urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
      method: "GET",
      data: {
        WebsiteUrl: bussinessPathUrl,
        BusinesslocationId:
          e !== undefined ? e : this.props.bannerBusinessLocationId,
      },

      onSuccess: (response) => {
        if (response[0].errorCode !== "500") {
          const allCartItems = this.props.global.allProductDetail.products;
          const allCategorShow = JSON.parse(response[0].categories).filter(
            (item) => item.Categories != null
          );
          const cartBusinessIds = allCartItems.map((item) => {
            return item.addItem.businessId;
          });
          const currentBusinessId = response[0].businessId;
          const isSameId = cartBusinessIds.every(
            (currentValue) => currentValue === currentBusinessId
          );
          if (!isSameId) {
            this.props.actions.DeleteAllItemDetail();
          }
          let themeColor = response[0].color;
          this.setState({ businessLocationId: response[0].businessLocationId });
          const root = document.documentElement;
          root?.style.setProperty("--main-color", themeColor);
          this.props.actions.storeLocationTimeZone(response[0].locationTimeZone);
          this.props.actions.storeInsideWordpress(this.props.insideWordpress);
          this.props.actions.storeThemeColor(response[0].color);
          this.props.actions.storeBussinessId(response[0].businessId);
          this.props.actions.storeBussinessDetail(response[0].image);
     
          this.setState({
            svgImages: JSON.parse(response[0].svgImages),
            color: response[0].color,
          });
          
          this.setState({
            businessId: response[0].businessId,
            isLocationSelector: response[0].isLocationSelector,
            insideWordpress: true,
          });
          if (this.props.isBannerImage ? null : allCategorShow.length === 1) {
            const singleCategory = allCategorShow[0];
            this.setState({
              businessCategoryType: singleCategory.businessCategoryTypeId,
              nocategory: true,
              HomePage: true,
              businessCategoryTypeId: singleCategory.businessCategoryTypeId,
              individualProductLoader: false,
              allCatergory: false,
              backButtonCatergory: true,
              showLocationDropdown: false,
            });
          } else if (!allCategorShow.length) {
            this.setState({
              nocategory: false,
              HomePage: true,
              allCatergory: false,
              backButtonCatergory: true,
              showLocationDropdown: false,
            });
          } else {
            this.setState({
              allCategories: allCategorShow,
              allCatergory: true,
              nocategory: true,
              locations: response[0].businessLocationId,
              backButtonCatergory: true,
              showLocationDropdown: true,
            });
          }
          this.props.actions.SHOWHEADERWEBSITE(response[0].widgetHeader);

          // this.props.actions.storeShowCount(true);
          this.setState({ banner: true, nocategory: true });
          this.setState({
            allTags:
              JSON.parse(response[0].categories)?.[0].Categories === null
                ? []
                : JSON.parse(
                    JSON.parse(response[0].categories)[0].Categories[0].Tags
                  ),
          });
        } else {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: response[0].responseMessage,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
        !this.state.isIndividualItemClickec && this.onIndividualItemFunction();
      },
    });
    
  };

  onBusinessClick = (addBusinessDetail) => {
    this.setState({
      businessCategoryType: addBusinessDetail.businessCategoryTypeId,
      nocategory: false,
      HomePage: true,
      businessCategoryTypeId: addBusinessDetail.businessCategoryTypeId,
      individualProductLoader: false,
      showLocationDropdown: false,
    });
  };

  bookNowModal = () => {
    this.setState({ HomePage: false });
  };
  onHandleChange = (e, { value }) => {
    this.backtochangeCategory(value, true);
  };
  getWidgetLocaion() {
    this.props.actions.apiCall({
      urls: ["GETBUSINESSLOCATION"],
      method: "GET",
      data: {
        InWebsiteWidget: 1,
        BusinessId: this.props.global.addBussinessId,
        BusinessLocationId: -1,
        pageNo: 1,
        pageSize: 100,
      },
      onSuccess: (response) => {
        let locationData = response.map((post) => {
          return {
            text: post.businessLocationName,
            value: post.businessLocationId,
            id: post.businessLocationId,
          };
        });
        this.setState({ locationOption: locationData });
      },
    });
  }
  onLocationBacktoCatergory = () => {
    this.props.allFuction.closeModalLocation();
  };
  render() {
    const { individualProductBookNowDetails, isLocationSelector} = this.props;
    const { locationOption, showLocationDropdown } = this.state;
               
    return (
      <div>
        <Modal
          open={this.props.openModal}
          onClose={this.props.closeModal}
          closeIcon
          size={"fullscreen"}
          className="bookNowModal"
        >
          <Modal.Content scrolling>
            {this.state.individualProductLoader && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}

            <Modal.Description className="orderPlaced">
              <div className=" flex justify-content-center w-full">
                <Container fluid>
                  {showLocationDropdown ? (
                    <Grid.Column
                      computer={15}
                      tablet={15}
                      mobile={15}
                      className="flex align-items-center"
                    >
                      <Popup
                        content="Back to location"
                        position="right center"
                        trigger={
                          <Icon
                            onClick={() => this.onLocationBacktoCatergory()}
                            name="arrow alternate circle left outline"
                            size="big"
                          />
                        }
                      />
                      <Form.Dropdown
                        selection
                        fluid
                        disabled={!isLocationSelector}
                        className="custom-select smWidth"
                        options={locationOption}
                        name="ddpLocation"
                        data={"locations"}
                        defaultValue={this.state.locations}
                        onChange={this.onHandleChange}
                      ></Form.Dropdown>
                    </Grid.Column>
                  ) : null}
                  {this.state.nocategory && (
                    <>
                      {this.state.allCatergory && (
                        <Grid className="verticalCenter flex align-items-center">
                          {this.state.allCategories.map(
                            (addBusinessDetail, index) => {
                              return (
                                <>
                                  <Grid.Column
                                    mobile={16}
                                    tablet={8}
                                    computer={4}
                                    textAlign="center"
                                    key={index}>
                                    <div
                                      className="orderPlacedItem itemsThumbnail"
                                      onClick={() =>
                                        this.onBusinessClick(addBusinessDetail)
                                      }
                                    >
                                      <div className="cmn-shad">
                                        <Header as="h3" textAlign="center">
                                          {addBusinessDetail.catregoryCodeName ===
                                          "Season Pass"
                                            ? "Passes"
                                            : addBusinessDetail.catregoryCodeName}
                                        </Header>
                                        <Image
                                          src={commonFunctions.concatenateImageWithAPIUrl(
                                            addBusinessDetail.Image
                                          )}
                                          fluid
                                        />
                                      </div>
                                    </div>
                                  </Grid.Column>
                                </>
                              );
                            }
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                   <AddCartPopUp 
                    bookNow={this.props.bookNow}
                    openaddtocart={this.props.openaddtocart}
                   />
                 
                  {this.state.HomePage && (
                    <HomeModal
                      history={this.props.history}
                      individualProductBookNowDetails={
                        individualProductBookNowDetails}
                      backTocatergoryWordpress={this.props.allFuction}
                      isShowAvailabletickets={this.props.isShowAvailabletickets}
                      isLocationSelector={this.props.isLocationSelector}
                      backButtonCatergory={this.state.backButtonCatergory}
                      allCatergory={this.state.allCatergory}
                      isItemSpecificBookNow={this.state?.isItemSpecificBookNow}
                      openModal={this.state.HomePage}
                      insideWordpress={this.props.insideWordpress}
                      isShowAvailableItem={this.props.isShowAvailableItem}
                      backtochangeCategory={this.backtochangeCategory}
                      closeModal={this.bookNowModal}
                      closeModalhome={this.props.closeModal}
                      bookNowState={this.state}
                      allFuction={this.props.allFuction}
                      locationSelcter={true}
                      closeWordpressModal={this.props.closeModal}
                    />
                  )}
                </Container>
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      StoreBussinessUrl: bindActionCreators(
        actions.StoreBussinessUrl,
        dispatch
      ),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      storeInsideWordpress: bindActionCreators(
        actions.storeInsideWordpress,
        dispatch
      ),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeShowCount: bindActionCreators(
        actions.storeShowCount,
        dispatch
      ),
      storeLocationTimeZone: bindActionCreators(actions.storeLocationTimeZone, dispatch),
      storeThemeColor: bindActionCreators(actions.storeThemeColor, dispatch),
      storeParentId: bindActionCreators(actions.storeParentId, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
      storeBussinessLocationId: bindActionCreators(
        actions.storeBussinessLocationId,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationViewData)
);
