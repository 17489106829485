import React, { Component } from "react";
import {
  Grid,
  Container,
  Divider,
  Form,
  Button,
  Dimmer,
  Loader,
  Popup,
  Icon,
} from "semantic-ui-react";
import { env } from "../../shared/functional/global-import";
import ScrollIntoView from "react-scroll-into-view";
import OrderErrorModal from "../../shared/components/organisms/modal/order-error/order-error";
import OrderProcessingModal from "../../shared/components/organisms/modal/order-processing/order-process";
import OrderPlacedModal from "../../shared/components/organisms/modal/order-success/order-sucess";
import DeleteCouponModal from "../../shared/components/organisms/modal/delete-coupon";
import SelectMail from "../../shared/components/organisms/modal/select-mail";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../shared/functional/global-import";
import { PhoneNumberInput } from "../../shared/components";
import disableScroll from "disable-scroll";
import moment from "moment";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import GoogleCalenderEvent from "./googleCalenderEvent";
import OrderDetailData from "./OrderDetailData";

const months = [
  { key: "01", value: "01", text: "Jan" },
  { key: "02", value: "02", text: "Feb" },
  { key: "03", value: "03", text: "Mar" },
  { key: "04", value: "04", text: "Apr" },
  { key: "05", value: "05", text: "May" },
  { key: "06", value: "06", text: "Jun" },
  { key: "07", value: "07", text: "Jul" },
  { key: "08", value: "08", text: "Aug" },
  { key: "09", value: "09", text: "Sep" },
  { key: "10", value: "10", text: "Oct" },
  { key: "11", value: "11", text: "Nov" },
  { key: "12", value: "12", text: "Dec" },
];

class MyCartPage extends Component {
  constructor(props) {
    super(props);
    this.individual = {
      ProductIndividualDetail:
        this.props.global?.allProductIndividualDetail.products.length > 0
          ? this.props.global?.allProductIndividualDetail
          : this.props.global?.allProductDetail,
    };
    this.state = {
      newAmount: 0,
      newSubAmount: "",
      discountCouponName: "",
      stateCode: this.props.global.codes,
      open: false,
      guid: null,
      tax: 0,
      showTaxes: 0,
      deleteCouponIndex: null,
      couponCodeID: null,
      openModal: false,
      openProcessingModal: false,
      openProcessingLoading: false,
      submitHandleLoader: false,
      openDeleteCouponModal: false,
      openModalSelectMail: false,
      hidePaymentSection: false,
      PaymentDetails: false,
      todayDate: "",
      errorImageUrl: "",
      years: "",
      showMultipleDiscounts: [],
      addProductsTocart: {
        memberCode: null,
        discounttitle: null,
        discountDate: new Date(),
        applicationId: env.APPLICATION_ID,
        businessId: this.props.global.addBussinessId,
        businessLocationId:
          this.individual?.ProductIndividualDetail.products[0]?.addItem
            .businessLocationId,
        customerId: -1,
        firstName: "",
        lastName: "",
        emailId: "",
        address: "",
        zipCode: "",
        city: "",
        stateId: null,
        phoneNumber: "",
        cardNumber: null,
        cvv: null,
        month: null,
        amount: null,
        year: null,
        token: null,
        addItemInCart: JSON.stringify(this.individual.ProductIndividualDetail),
        paymentMethod: 67,
        actionPerformedBy: "",
        widgetPaymentMethod: null,
        widgetPaymentMethodId: null,
      },
      currentDateFromApi: null,
      exactDate: null,
      showCalenderEvent: false,
      showCalenderEventData: null,
      userToken: [],
      businessLocation: "",
    };
    this.myDivToFocus = React.createRef();
    this.validator = commonFunctions.initializeSimpleValidator();
    this.paymentValidator = commonFunctions.initializeSimpleValidatorCommon();
  }
  componentDidMount = () => {
    const productdetail = this.individual?.ProductIndividualDetail;

    this.getOrderDate();
    this.getTaxPrice(productdetail);
    let themeColor = this.props.global.themeColor;
    const root = document.documentElement;
    root?.style.setProperty("--main-color", themeColor);

    this.setState({
      newAmount: productdetail?.products.reduce(
        (previousScore, currentScore, index) =>
          previousScore + JSON.parse(currentScore.addItem.totalprice),
        0
      ),
    });
    this.setState({
      addProductsTocart: {
        ...this.state.addProductsTocart,
        amount: productdetail?.products.reduce(
          (previousScore, currentScore, index) =>
            previousScore + JSON.parse(currentScore.addItem.totalprice),
          0
        ),
      },
    });

    let now = new Date().getUTCFullYear();
    let allYears = Array(now - (now - 10))
      .fill("")
      .map((v, idx) => now + idx);
    let years = [];
    allYears.map((allYear) => {
      years.push({
        key: allYear,
        value: allYear,
        text: allYear,
      });
      return { ...allYear };
    });
    this.setState({ years });
    this.getEmailDetail();
  };
  getEmailDetail = () => {
    const productdetail = this.individual?.ProductIndividualDetail;
    this.props.actions.apiCall({
      urls: ["GETGOOGLECALENDERDETAIL"],
      method: "GET",
      data: {
        businessId: this.props.global.addBussinessId,
        businessLocationId:
          productdetail?.products[0].addItem.businessLocationId,
      },
      onSuccess: (response) => {
        const userTokens = response.map((item) => ({
          token: item.accessToken,
          email: item.userEmail,
          userName: item.userName,
          businessCategoryId: item.businessCategoryId,
        }));
        // Now set the state with the transformed array
        this.setState({ userToken: userTokens });
      },
    });
  };

  getOrderDate = () => {
    var today = new Date();
    let date = moment(today).format("MMM DD, YYYY , h:mm a");
    this.setState({ todayDate: date });
  };
  getTaxPrice = (productdetail) => {
    const amount = productdetail?.products.reduce(
      (previousScore, currentScore, index) =>
        previousScore + JSON.parse(currentScore.addItem.totalprice),
      0
    );
    const addTax = productdetail?.products.reduce(
      (previousScore, currentScore, index) =>
        previousScore + JSON.parse(currentScore.addItem.totalTaxAmount),
      0
    );
    const { addProductsTocart } = this.state;
    this.props.actions.apiCall({
      urls: ["APPLYTAXONITEMS"],
      method: "GET",
      data: {
        applicationId: env.APPLICATION_ID,
        BusinessId: this.props.global.addBussinessId,
        BusinessLocationId:
          productdetail?.products[0]?.addItem.businessLocationId,
        BusinessCategoryType: null,
        Amount: amount,
      },
      onSuccess: (response) => {
        let pp =
          response[0]?.amount1 +
          addTax +
          response[0]?.totalAmountwithRehntitShare;
        this.setState({
          addProductsTocart: {
            ...this.state.addProductsTocart,
            amount: pp.toFixed(2),
            totalAmountwithRehntitShare:
              response[0]?.totalAmountwithRehntitShare,
          },
          widgetPaymentMethod: response[0]?.widgetPaymentMethod,
          widgetPaymentMethodId: response[0]?.widgetPaymentMethodId,
        });
        addProductsTocart.amount = response[0].amount1.toFixed(2);
        let aa = response[0]?.discountAmount + addTax;
        let orderTotal = this.state.newAmount + aa;
        this.setState({
          tax: aa.toFixed(2),
          showTaxes: aa.toFixed(2),
          orderTotal: orderTotal.toFixed(2),
        });
      },
    });
  };

  onHandleChange = (e, { name, value, type, checked, data }) => {
    var addProductsTocart = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addProductsTocart,
      this
    );
    this.setState({ addProductsTocart });
  };
  // This function is used to set handle change

  onHandleChangestate = (e, { name, value, type, checked, data }) => {
    var addProductsTocart = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addProductsTocart,
      this
    );
    this.setState({ addProductsTocart });
  };

  onCouponChange = (e, { value }) => {
    this.setState({ discountCouponName: value.trim() });
  };
  onChangeProductDetails = (e, { name, value, type, checked, data }) => {
    if (name === "txtCardNumber" && value.length > 16) return;
    var addProductsTocart = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.addProductsTocart,
      this
    );
    this.setState({ addProductsTocart });
  };

  onHandleChangeEmail = () => {
    this.props.actions.apiCall({
      urls: ["GETDETAILFROMEMAIL"],
      method: "GET",
      data: {
        EmailId: this.state.addProductsTocart.emailId,
        BusinessId: this.props.global.addBussinessId,
      },
      onSuccess: (response) => {
        const { addProductsTocart } = this.state;
        if (response[0].respose === null) {
          if (response.length > 1) {
            this.setState({
              allCoustomer: response,
              openModalSelectMail: !this.state.openModalSelectMail,
            });
          } else {
            addProductsTocart.lastName =
              response[0].lastName === null ? "" : response[0].lastName;
            addProductsTocart.firstName = response[0].firstName;
            addProductsTocart.address = response[0].address;
            addProductsTocart.customerId = response[0].customerId;
            addProductsTocart.stateId = response[0].stateId;
            addProductsTocart.zipCode = response[0].zipCode;
            addProductsTocart.city = response[0].city;
            addProductsTocart.phoneNumber = response[0].phoneNumber;
            addProductsTocart.actionPerformedBy = response[0].emailId;
          }
        } else if (response[0].businessId === 0) {
          addProductsTocart.customerId = -1;
          addProductsTocart.lastName = "";
          addProductsTocart.firstName = "";
          addProductsTocart.address = "";
          addProductsTocart.stateId = null;
          addProductsTocart.zipCode = "";
          addProductsTocart.city = "";
          addProductsTocart.phoneNumber = "+1";
          addProductsTocart.actionPerformedBy = "";
          addProductsTocart.cardNumber = null;
          addProductsTocart.cvv = null;
          addProductsTocart.month = 0;
          addProductsTocart.year = 0;
        }

        this.validator = commonFunctions.initializeSimpleValidator();
      },
    });
  };

  onHandleClearForm = () => {
    this.setState({
      addProductsTocart: {
        ...this.state.addProductsTocart,
        firstName: "",
        lastName: "",
        customerId: -1,
        address: "",
        zipCode: "",
        stateId: "",
        city: "",
        phoneNumber: "",
        cardNumber: null,
        cvv: null,
        month: 0,
        year: 0,
        emailId: "",
      },
    });
  };

  onHandleClearForm2 = () => {
    this.setState({
      addProductsTocart: {
        ...this.state.addProductsTocart,
        firstName: "",
        lastName: "",
        customerId: -1,
        address: "",
        zipCode: "",
        discountAmount: "",
        newAmount: 0,
        amount: 0,
        stateId: "",
        city: "",
        phoneNumber: "",
        cardNumber: null,
        cvv: null,
        month: 0,
        year: 0,
        emailId: "",
      },
    });
    this.setState({
      newAmount: 0,
    });
  };

  handleChange = (e, { value }) => this.setState({ value });
  //Apply Discount on Items
  applyDiscountOnItems = () => {
    const {
      addProductsTocart,
      discountCouponName,
      newAmount,
      newSubAmount,
      showMultipleDiscounts,
      showTaxes,
    } = this.state;
    const productdetail = this.individual?.ProductIndividualDetail;
    let checkDiscountCouponName =
      showMultipleDiscounts.length > 0
        ? showMultipleDiscounts.every(
            (e) =>
              e.discountTittle.toLowerCase() != discountCouponName.toLowerCase()
          )
        : true;
    if (checkDiscountCouponName) {
      let totalDiscount = 0;
      showMultipleDiscounts.map((discountObj) => {
        totalDiscount += discountObj.discountAmount;
        return discountObj;
      });
      this.props.actions.apiCall({
        urls: ["APPLYDISCOUNTONITEMS"],
        method: "POST",
        data: {
          discounttitle: discountCouponName,
          taxes: showTaxes,
          discountDate:
            this.state.exactDate === null
              ? this.state.addProductsTocart.discountDate
              : this.state.exactDate,
          amount: newSubAmount != "" ? newSubAmount : newAmount,
          actionPerformedBy: this.state.addProductsTocart.actionPerformedBy,
          businessId: this.props.global.addBussinessId,
          guid: this.state.guid,
          subTotal: newSubAmount != "" ? newSubAmount : newAmount,
          discountTotal: totalDiscount,
          businessLocationId:
            productdetail?.products[0].addItem.businessLocationId,
        },

        onSuccess: (response) => {
          let dicountArry = this.state.showMultipleDiscounts;
          response["discountTittle"] = discountCouponName;
          dicountArry.push(response);
          this.setState({
            showMultipleDiscounts: dicountArry,
            guid: response.guid,
          });
          addProductsTocart.memberCode = discountCouponName;
          addProductsTocart.discountAmount = response.discountAmount;
          this.setState({
            addProductsTocart,
            newSubAmount: response?.subTotal,
            discountCouponName: "",
            showTaxes: response?.taxes,
            orderTotal: response?.orderTotal,
          });
        },
        showNotification: true,
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "You can't use the same coupon.",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
    this.setState({
      businessLocation: productdetail?.products[0].addItem.businessLocationId,
    });
  };

  // For send Email after order
  onSendEmail = (response) => {
    this.props.actions.apiCall({
      urls: ["ORDERSUMMARYPRINTANDEMAIL"],
      method: "GET",
      data: {
        applicationId: env.APPLICATION_ID,
        BusinessId: this.props.global.addBussinessId,
        emailId: this.state.addProductsTocart.emailId,
        isViewOrPrint: false,
        orderId: response.keyId,
      },
      onSuccess: (response) => {
        return response;
      },
      showNotification: true,
    });
  };

  removeDiscountAndcuopons = (e, id, index) => {
    this.setState({
      openDeleteCouponModal: !this.state.openDeleteCouponModal,
      couponCodeID: id,
      deleteCouponIndex: index,
    });
  };

  deleteSessonPassOrCopon = () => {
    const { newSubAmount } = this.state;
    const productdetail = this.individual?.ProductIndividualDetail;
    this.props.actions.apiCall({
      urls: ["DELETESEASONPASSDISCOUNTCOUPON"],
      method: "DELETE",
      data: {
        businessId: this.props.global.addBussinessId,
        seasonPassDiscountUsageId: this.state.couponCodeID,
        totalAmount: newSubAmount,
        businessLocationId:
          productdetail?.products[0].addItem.businessLocationId,
      },
      onSuccess: (response) => {
        const { addProductsTocart } = this.state;
        const multipleCouponDetails = this.state.showMultipleDiscounts.filter(
          (_, i) => i !== this.state.deleteCouponIndex
        );

        this.setState({
          openDeleteCouponModal: false,
          addProductsTocart,
          newSubAmount: response.subtotal === 0 ? "" : response.subtotal,
          discountCouponName: "",
          showTaxes: response?.taxes,
          deleteCouponIndex: null,
          couponCodeID: null,
          orderTotal: response?.totalAmount,
          showMultipleDiscounts: multipleCouponDetails,
        });
      },
      showNotification: true,
    });
  };
  // *************  Comment the google calender function don`t remove comment function ***********//
  onGetSubmitDataEvent = (data, addProductsTocart) => {
    this.props.actions.apiCall({
      urls: ["GETGOOGLECALENDEREVENT"],
      method: "GET",
      data: {
        orderId: data,
      },
      onSuccess: (response) => {
        this.setState({
          showCalenderEvent: true,
          showCalenderEventData: !response.summary ? null : response,
          open: !this.state.open,
          openProcessingModal: false,
        });
      },
    });
  };
  // Add Order or submit details in widget
  onSubmitDetails = (e, stripeTokenId) => {
    const addProductsTocart = this.state.addProductsTocart;
    const discountDetails = this.state.showMultipleDiscounts.map(
      (multipleDisObj) => {
        const obj = {
          memberCode: multipleDisObj.discountTittle,
          amount: multipleDisObj.amount,
          discountType: multipleDisObj.discountType,
          discountAmount: multipleDisObj.discountAmount,
          discount: multipleDisObj.discountPrice,
        };
        return { ...obj };
      }
    );
    const discountJson = JSON.stringify(discountDetails);
    addProductsTocart.discounttitle = discountJson;
    addProductsTocart.amount = this.state.orderTotal;
    addProductsTocart.subtotal =
      this.state.showMultipleDiscounts.length > 0
        ? this.state.newSubAmount
        : this.state.newAmount;
    addProductsTocart.token =
      stripeTokenId === undefined ? null : stripeTokenId;

    if (
      addProductsTocart.firstName === "" ||
      addProductsTocart.phoneNumber === "+1"
    ) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please Fill the Customer-Details",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (this.paymentValidator.allValid() === true) {
      this.setState({
        openProcessingModal: !this.state.openProcessingModal,
        openProcessingLoading: true,
      });
      this.props.actions.apiCall({
        urls: ["ADDPRODUCTTOCART"],
        method: "POST",
        data: addProductsTocart,
        onSuccess: (response) => {
                       
          if (response.responseCode !== 200) {
            // ************* comment the google calender function dont remove comment function ***********//
            this.onGetSubmitDataEvent(response.keyId, addProductsTocart);
            return;
          } else {
            this.onSendEmail(response);
            this.onHandleClearForm2();
            this.props.actions.DeleteAllItemDetail();
            this.props.actions.DeleteItemIndividualDetail();
            this.onGetSubmitDataEvent(response.keyId, addProductsTocart);
          }
        },
        onFailure: (response) => {
          if (
            response.response.data.responseMessage ===
            "Discount Coupon has been already used."
          ) {
            this.props.actions.showNotification(
              {
                title: "Warning",
                message: response.response.data.responseMessage,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              },
              "error"
            );
          } else {
            this.setState({
              openModal: !this.state.openModal,
              openProcessingModal: false,
              openProcessingLoading: false,
              submitHandleLoader: false,
            });
          }
        },
      });
    } else {
      this.paymentValidator.showMessages();
      this.forceUpdate();
    }
  };
  handleOnClick = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.setState({ PaymentDetails: true }, () => {
        disableScroll.off();
        if (this.myDivToFocus?.current) {
          this.myDivToFocus.current.scrollIntoView({ behavior: "smooth" });
        }
      });
    } else {
      this.setState({ PaymentDetails: false });
    }
  };
  paymentZeroAmount = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.paymentValidator.purgeFields();
      this.onSubmitDetails();
    }
  };
  onHandleBackToShop = (isToroute) => {
    // eslint-disable-next-line no-restricted-globals
    const url = location.pathname;
    if (this.props.global?.allProductIndividualDetail.products.length > 0) {
      let obj = {
        selectedDate:
          this.props.global?.allProductIndividualDetail.selectedDate,
        selectedTime:
          this.props.global?.allProductIndividualDetail.selectedTime,
        subtotal: this.props.global?.allProductIndividualDetail.subtotal,
        totalprice: this.props.global?.allProductIndividualDetail.totalprice,
      };
      this.props.actions.AllItemDetailToPay([
        {
          addItem:
            this.props.global?.allProductIndividualDetail.products[0].addItem,
        },
        { extraDetail: obj },
      ]);
    }
    this.props.actions.DeleteItemIndividualDetail();
    if (isToroute) {
      if (url === "/my-cart") {
        this.props.history.push(`${env.PUBLIC_URL}`);
      } else {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
    this.onHandleClearForm2();
  };
  onHandleBackToShopNew = () => {
    this.onHandleBackToShop(true);
    this.props.history.push(
      `${env.PUBLIC_URL}${this.props.global.bussinessUrl}`
    );
    this.props.actions.storeShowCount(true);
  };


  onHandleBacktoShopCatergory = () => {
           
    if (this.props.global.wordpressBacktoStop) {
      if (this.props.closeModalAddtocart === undefined) {
        this.onHandleBackToShop(true);
      } else {
        this.props.closeModalAddtocart();
      }
      this.props.actions.storeShowCount(true);
    } else {
      this.onHandleBackToShop(true);
    }
  };
  render() {
    const openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    };
    const { api, bookNow } = this.props;
    const {
      PaymentDetails,
      addProductsTocart,
      openModal,
      openProcessingModal,
      openDeleteCouponModal,
      open,
      openModalSelectMail,
      submitHandleLoader,
      showCalenderEvent,
    } = this.state;
    const nullAmount = this.state.orderTotal === 0 ? false : true;

    return (
      <Container className="myCart">
        {openProcessingModal && (
          <>
            <OrderProcessingModal
              openModal={openProcessingModal}
              onclose={() => this.setState({ openProcessingModal: false })}
            />
          </>
        )}
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <>
              {api.isApiLoading && (
                <Dimmer active inverted>
                  {" "}
                  <Loader size="small"></Loader>{" "}
                </Dimmer>
              )}

              <div>
                <Grid>
                  <OrderDetailData
                    allFuction={this}
                    fullstate={this.state}
                    backToWordpressModal={this.props.backToWordpressModal}
                    allFunction={this.props.allFunction}
                    closeWordpressModal={this.props.closeWordpressModal}
                    ProductIndividualDetail={
                      this.individual?.ProductIndividualDetail.products
                    }
                    bookNow={this.props}
                  />

                  <Grid.Column
                    floated="right"
                    computer={10}
                    tablet={16}
                    mobile={16}
                  >
                    <Form
                      onSubmit={async (event) => {
                        this.setState({ submitHandleLoader: true });
                        event.preventDefault();
                        if (elements == null) {
                          return;
                        }
                        if (!stripe || !elements) {
                          // Stripe.js has not loaded yet. Make sure to disable
                          // form submission until Stripe.js has loaded.
                          return;
                        }
                        const payload = await stripe.createToken(
                          elements.getElement(CardElement)
                        );
                        this.onSubmitDetails(event, payload?.token?.id);
                      }}
                    >
                      <div className="myCartRight">
                        <Grid.Column width={16}>
                          <Form.Input
                            onBlur={this.onHandleChangeEmail}
                            label="Email"
                            name="txtcustomerEmail"
                            data="emailId"
                            className="asteriskIcon"
                            onChange={this.onChangeProductDetails}
                            value={addProductsTocart.emailId}
                            placeholder="Email"
                            error={this.validator.message(
                              "Email",
                              addProductsTocart.emailId,
                              `required|email`
                            )}
                          />
                        </Grid.Column>
                        <Divider hidden />
                        <div className="body mt-4">
                          <p>Customer Details</p>
                          <Grid selector="#footer7">
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                              <Form.Input
                                label="First Name"
                                name="txtcustomerName"
                                data="firstName"
                                onChange={this.onChangeProductDetails}
                                value={addProductsTocart.firstName}
                                placeholder="First Name"
                                error={this.validator.message(
                                  "firstName",
                                  addProductsTocart.firstName,
                                  `required`
                                )}
                              />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                              <Form.Input
                                label="Last Name"
                                name="txtcustomerName"
                                data="lastName"
                                onChange={this.onChangeProductDetails}
                                placeholder="Last Name"
                                value={addProductsTocart.lastName}
                              />
                            </Grid.Column>
                            <Grid.Column width={16}>
                              <Form.TextArea
                                type="textarea"
                                rows="2"
                                label="Address"
                                name="txtcustomerAddress"
                                onChange={this.onChangeProductDetails}
                                data="address"
                                value={addProductsTocart.address}
                              />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                              <Form.Input
                                name="txtcustomerCity"
                                data="city"
                                onChange={this.onChangeProductDetails}
                                label={
                                  <label>
                                    City
                                    <Popup
                                      content="City & Country (If International)"
                                      trigger={
                                        <Icon
                                          name="info circle"
                                          className="orange-color"
                                        />
                                      }
                                    />
                                  </label>
                                }
                                placeholder="City"
                                value={addProductsTocart.city}
                              />
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                              <Form.Select
                                label="State"
                                type="select"
                                placeholder="State"
                                options={this.state.stateCode}
                                data="stateId"
                                value={addProductsTocart.stateId}
                                onChange={this.onChangeProductDetails}
                                error={this.validator.message(
                                  "State",
                                  addProductsTocart.stateId,
                                  `required`
                                )}
                              />
                            </Grid.Column>

                            <Grid.Column mobile={16} tablet={8} computer={8}>
                              <div className="field">
                                <label>Phone Number</label>
                                <PhoneNumberInput
                                  onChange={(
                                    value,
                                    country,
                                    e,
                                    formattedValue
                                  ) =>
                                    this.onHandleChange(e, {
                                      name: "phnPhoneNumber",
                                      value: formattedValue,
                                      type: "phoneNumber",
                                      data: "phoneNumber",
                                    })
                                  }
                                  value={addProductsTocart.phoneNumber}
                                  error={this.validator.message(
                                    "phoneNumber",
                                    addProductsTocart.phoneNumber,
                                    `required|phone`
                                  )}
                                />
                              </div>
                            </Grid.Column>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                              <Form.Input
                                label="Zip Code"
                                placeholder="Zip Code"
                                name="txtcustomerZipcode"
                                data="zipCode"
                                onChange={this.onChangeProductDetails}
                                value={addProductsTocart.zipCode}
                              />
                            </Grid.Column>
                            {nullAmount && (
                              <Grid.Column width={16}>
                                <ScrollIntoView
                                  smooth={true}
                                  selector="#footer6"
                                >
                                  <Button
                                    className="primaryBtn"
                                    onClick={(e) => {
                                      this.handleOnClick(e);
                                    }}
                                    fluid
                                  >
                                    Continue
                                  </Button>
                                </ScrollIntoView>
                              </Grid.Column>
                            )}
                            {!nullAmount && (
                              <Grid.Column width={16}>
                                <Button
                                  className="primaryBtn"
                                  onClick={(e) => {
                                    this.paymentZeroAmount(e);
                                  }}
                                  floated="right"
                                  fluid
                                >
                                  Continue & Pay
                                </Button>
                              </Grid.Column>
                            )}
                          </Grid>
                        </div>

                        <Divider hidden />
                        {PaymentDetails === true && nullAmount ? (
                          <Grid.Column
                            floated="right"
                            computer={10}
                            tablet={16}
                            mobile={16}
                            id="footer6"
                          >
                            <div
                              ref={this.myDivToFocus}
                              className="myCartRight"
                            >
                              <div className="body">
                                <p>
                                  {" "}
                                  <strong>Payment Details</strong>
                                </p>
                                {this.state.widgetPaymentMethod ===
                                  "Stripe" && (
                                  <Grid>
                                    <Grid.Column width={16}>
                                      <div className="card-element-container">
                                        <label htmlFor="card-element"></label>
                                        <CardElement
                                          id="card-element"
                                          options={{
                                            style: {
                                              base: {
                                                fontSize: "16px",
                                                color: "#424770",
                                                "::placeholder": {
                                                  color: "#aab7c4",
                                                },
                                              },
                                              invalid: {
                                                color: "#9e2146",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                      <Button
                                        className="primaryBtn"
                                        floated="right"
                                        disabled={submitHandleLoader}
                                        type="submit"
                                        fluid
                                      >
                                        Continue & Pay
                                      </Button>
                                    </Grid.Column>
                                  </Grid>
                                )}
                                {this.state.widgetPaymentMethod ===
                                  "EdgePay" && (
                                  <Grid>
                                    <Grid.Column width={16}>
                                      <Form.Input
                                        label="Credit Card Number"
                                        type="number"
                                        placeholder="Enter Card Number"
                                        name="txtCardNumber"
                                        data="cardNumber"
                                        onChange={this.onChangeProductDetails}
                                        value={addProductsTocart.cardNumber}
                                        error={this.paymentValidator.message(
                                          "cardNumber",
                                          addProductsTocart.cardNumber,
                                          `required|card_num|max:16`
                                        )}
                                      />
                                    </Grid.Column>

                                    <Grid.Column width={16}>
                                      <Form.Input
                                        label="CVV"
                                        placeholder="CVV"
                                        type="number"
                                        data="cvv"
                                        name="txtCVV"
                                        className="custom-select"
                                        search
                                        selection
                                        onChange={this.onChangeProductDetails}
                                        value={addProductsTocart.cvv}
                                        error={this.paymentValidator.message(
                                          "cvv",
                                          addProductsTocart.cvv,
                                          `numeric|min:3|max:4|required`
                                        )}
                                      />
                                    </Grid.Column>
                                    <Grid.Column width={8} mobile={16}>
                                      <Form.Dropdown
                                        label="Expiry Month"
                                        placeholder="Expiry Month"
                                        type="select"
                                        data="month"
                                        name="ddpMonths"
                                        options={months}
                                        className="custom-select"
                                        search
                                        selection
                                        onChange={this.onChangeProductDetails}
                                        value={addProductsTocart.month}
                                        error={this.paymentValidator.message(
                                          "month",
                                          addProductsTocart.month,
                                          `required`
                                        )}
                                      />
                                    </Grid.Column>
                                    <Grid.Column width={8} mobile={16}>
                                      <Form.Dropdown
                                        label="Expiry Year"
                                        placeholder="Expiry Year"
                                        type="select"
                                        data="year"
                                        search
                                        name="ddpYears"
                                        options={this.state.years}
                                        className="custom-select"
                                        selection
                                        onChange={this.onChangeProductDetails}
                                        value={addProductsTocart.year}
                                        error={this.paymentValidator.message(
                                          "year",
                                          addProductsTocart.year,
                                          `required`
                                        )}
                                      />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                      <Button
                                        disabled={api.isApiLoading}
                                        className="primaryBtn"
                                        onClick={(e) => this.onSubmitDetails(e)}
                                        floated="right"
                                        fluid
                                      >
                                        Continue & Pay
                                      </Button>
                                    </Grid.Column>
                                  </Grid>
                                )}
                              </div>
                            </div>
                            <Divider hidden />
                            <span>
                              By clicking Continue & Pay, you agree to the{" "}
                              <span>AHOY</span>{" "}
                              <b className="clickingContinue">
                                <u
                                  className="Privacy Policy"
                                  onClick={() =>
                                    openInNewTab(
                                      "https://ahoybooking.com/privacy-policy"
                                    )
                                  }
                                >
                                  Privacy Policy
                                </u>
                                &
                                <u
                                  className="Privacy Policy"
                                  onClick={() =>
                                    openInNewTab(
                                      "https://ahoybooking.com/terms-of-service"
                                    )
                                  }
                                >
                                  Terms of Service
                                </u>
                              </b>
                            </span>
                            <Divider hidden />
                            {/*  for wordpress */}
                            {this.props.global.insideWordpress ? (
                              <Button
                                className="secondaryBtn"
                                floated="right"
                                onClick={this.onHandleBacktoShopCatergory}
                              >
                                <Icon name="chevron left" />
                                Back to shop
                              </Button>
                            ) : (
                              //  widget scenario
                              <Button
                                className="secondaryBtn"
                                floated="right"
                                onClick={this.onHandleBackToShopNew}
                              >
                                <Icon name="chevron left" />
                                Back to Shop
                              </Button>
                            )}
                          </Grid.Column>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </Grid.Column>
                  {/* Google calender event  */}
                  {showCalenderEvent && (
                    <GoogleCalenderEvent
                      businessLocationId={
                        this.individual?.ProductIndividualDetail.products[0]
                          ?.addItem.businessLocationId
                      }
                      fullState={this.state}
                      allFunction={this}
                    />
                  )}

                  <OrderErrorModal
                    openModal={openModal}
                    onclose={() => this.setState({ openModal: false })}
                  ></OrderErrorModal>
                  <DeleteCouponModal
                    deleteSessonPassOrCopon={this.deleteSessonPassOrCopon}
                    openModal={openDeleteCouponModal}
                    isApiLoading={api.isApiLoading}
                    onclose={() =>
                      this.setState({ openDeleteCouponModal: false })
                    }
                    api={api}
                  ></DeleteCouponModal>
                  <OrderPlacedModal
                    openModal={open}
                    bookNow={bookNow}
                    onClose={() => this.setState({ open: false })}
                    insideWordpress={this.props.insideWordpress}
                    onHandleBackToHome={this.props.closeModalhome}
                  ></OrderPlacedModal>
                  <SelectMail
                    allFuction={this}
                    allCoustomer={this.state.allCoustomer}
                    openModal={openModalSelectMail}
                    onclose={() =>
                      this.setState({ openModalSelectMail: false })
                    }
                  ></SelectMail>
                </Grid>
              </div>
            </>
          )}
        </ElementsConsumer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeItemDetail: bindActionCreators(actions.storeItemDetail, dispatch),
      AllItemDetailToPay: bindActionCreators(
        actions.AllItemDetailToPay,
        dispatch
      ),
      storeShowCount: bindActionCreators(actions.storeShowCount, dispatch),
      cartItemDelete: bindActionCreators(actions.cartItemDelete, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),

      DeleteItemIndividualDetail: bindActionCreators(
        actions.DeleteItemIndividualDetail,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCartPage);
